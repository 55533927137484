<template>
    <loading v-if='loading' :progress="progress" :progressMessage="progressMessage"/>
    <div v-else-if="$vuetify.breakpoint.xs">
        Team selection functions are not available on small screen formats. Please try using a tablet ot destop browser to take advantage of all the team selection facilities
    </div>
    <div v-else style="width:100%">
        <v-dialog v-model="showReview" max-width="90%" width="90%">
        </v-dialog>

        <!--
        <v-row class="no-boundaries">
            <v-col  class="no-boundaries" cols="8">
                <v-row class="no-boundaries" style="margin-top:auto;margin-bottom:auto;">
                    <v-col  class="no-boundaries" cols="1"></v-col>
                    <v-col cols="6" class="no-boundaries" style="padding-left:5px;padding-right:5px;">
                        <div class="page-title" > 
                            Team Selections
                        </div>
                        <div style="display:none">{{ updateFlag }}</div>
                    </v-col>    
                    <v-col class="no-boundaries" cols="1" style="margin-top:auto;margin-bottom:auto;">
                        <v-btn text color="primary" x-small v-if="selectedPlayers.length>0" @click="showReview=true">{{selectedPlayers.length===1?"Review Player":"Compare Players"}}</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        --> 
        <v-row v-if="updateFlag>0" class="no-boundaries">
            <v-col cols="8" sm="8" md="8" lg="8" xl="8" class="no-boundaries" style="margin-top:10px;">

                <div style="display:flex;flex-flow:row;">
                    <v-tabs v-model="pageTab">
                        <v-tab key="0">Squad Overview</v-tab>
                        <v-tab key="1">Player Comparision</v-tab>
                        <v-tab key="2">Team Selections</v-tab>
                    </v-tabs>
                    <div style="display:flex;flex-grow:1;"/>
                    <ParticipantFilterDialog disableSmartFilter defaultShowDiscard/>
                </div>
                <v-tabs-items v-model="pageTab">
                    <v-tab-item key="0">
                        <v-select style="margin-left:20px;width:30%;min-width:200px;" v-model="selectedWeighting" label="Role to assess" :items="availableRoles" item-text="text" item-value="value"></v-select>
                        <v-data-table 
                            fixed-header
                            height="60vh"
                            :headers="tableHeaders"
                            :items="tableData"
                            :items-per-page="-1"
                            multi-sort
                            :sort-by="sortBy"
                            class="elevation-1"
                            @click:row="clickRow"
                        >
                        <template v-slot:item="props">
                            <tr @click="selectParticipant(props.item.participantId)" :class="{'selected-row': selectedPlayers.includes(props.item.participantId)}">
                                <!-- 
                                <td><div class="player-number">{{ props.item.participantNumber }}</div></td>
                                <td>{{ props.item.participantName }}</td>
                                -->
                            <td draggable @dragstart="startDrag($event, {participantId: props.item.participantId, fromTeamId: participants[props.item.participantId].offer, fromResponse: participants[props.item.participantId].offerResponse})"> 

                                <player-select-button teamSelector :participantId="props.item.participantId" v-on:click.native="selectParticipant(props.item.participantId)" :selected="selectedPlayers.includes(props.item.participantId)"/> 
                                <div class="offer-text">
                                    {{ props.item.offer }}
                                </div>
                            </td>
                                <td>{{  props.item.rank }}</td>
                                <td>{{ props.item.weightedScore.toFixed(1) }}</td>
                                <td v-for="crit in weightedCriterionList" :key="props.item.participantId +'-'+ crit" class="text-xs-right" >
                                    <v-progress-circular  :background="'blue'" :color="completionColor(props.item[crit].complete)" :value="props.item[crit].complete" rotate="-90" >
                                        <span :style="scoreStyle(props.item[crit].average)"> {{ props.item[crit].average.toFixed(1) }}</span>
                                    </v-progress-circular>
                                </td>
                                <div style="display:none;">
                                </div>
                            </tr>
                        </template>
                        </v-data-table>
                    </v-tab-item>
                    <v-tab-item key="1">
                        <v-card style="padding-top:10px">
                            <v-card-text v-if="selectedPlayers.length>0">
                                <v-row class="no-boundaries" style="margin-top:10px;">
                                    <v-col cols="2" class="heading-column">
                                    </v-col>
                                    <v-divider vertical></v-divider>
                                    <v-col cols="10" class="no-boundaries">
                                        <v-row class="no-boundaries">
                                            <v-col v-for="selectedPlayerId in selectedPlayers" :key="'S-'+selectedPlayerId" :cols="12/selectedPlayers.length" class="compare-cell">
                                                <!-- 
                                                <AthleteCard :participantId="selectedPlayerId" forceLandscape size="80%" />
                                                -->
                                                <div class="player-info">
                                                    <div>
                                                        <player-select-button teamSelector :participantId="selectedPlayerId" v-on:click.native="selectParticipant(selectedPlayerId)" :selected="selectedPlayers.includes(selectedPlayerId)"/>
                                                        <v-btn x-small color="secondary" v-if="account.user.details.beta && aiStatus.idle && !aiStatus.ready" :disabled="aiStatus.loading" @click="genFeedback(selectedPlayerId)">Generate Report</v-btn>
                                                        <v-progress-linear v-if="account.user.details.beta && aiStatus.loading"
                                                            indeterminate
                                                            color="green"
                                                        ></v-progress-linear>
                                                        <RenderAIReport v-if="account.user.details.beta && aiStatus.ready" :participantId="selectedPlayerId"/>
                                                    </div>
                                                    <div class="role-wrapper">
                                                        Player Role Preferences
                                                        <div v-if="role1(selectedPlayerId)" class="role-pill" style="margin-bottom:2px">{{ role1(selectedPlayerId) }}</div>
                                                        <div v-if="role2(selectedPlayerId)" class="role-pill">{{ role2(selectedPlayerId) }}</div>
                                                    </div>
                                                    <div style="display:flex;flex-grow:1"/>
                                                    <div style="padding-right:10px;">
                                                        <participant-profile-image :participantId="selectedPlayerId" size="90px"/>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-divider/>
                                <v-row class="no-boundaries">
                                    <v-col cols="2" class="heading-column">
                                            Skill Ratings
                                    </v-col>
                                    <v-divider vertical></v-divider>
                                    <v-col class="no-boundaries">
                                        <v-row class="no-boundaries">
                                            <v-col v-for="selectedPlayerId in selectedPlayers" :key="selectedPlayerId" :cols="12/selectedPlayers.length" class="compare-cell">
                                                <SkillStack :participantId="selectedPlayerId" :width="200" :height="100"/>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-divider/>
                                <v-row class="no-boundaries">
                                    <v-col cols="2" class="heading-column">
                                        Attendance
                                    </v-col>
                                    <v-divider vertical></v-divider>
                                    <v-col cols="10" class="no-boundaries">
                                        <v-row class="no-boundaries" >
                                            <v-col v-for="n in selectedPlayers" :key="'A-'+n" :cols="12/selectedPlayers.length" class="compare-cell">
                                                    {{ recordedAttendance(0)}} sessions
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-divider/>
                                <v-row class="no-boundaires">
                                    <v-col cols="2" class="heading-column">
                                        Recommendations
                                    </v-col>
                                    <v-divider vertical/>
                                    <v-col class="no-boundaries">
                                        <v-row class="no-boundaries">
                                            <v-col v-for="n in selectedPlayers" :key="'R-'+n" :cols="12/selectedPlayers.length" class="compare-cell">
                                                <v-row class="no-boundaries">
                                                    <v-col cols="4" class="reccommendation-heading">
                                                        Team
                                                    </v-col>
                                                    <v-col cols="4" class="reccommendation-heading">
                                                        Role 1
                                                    </v-col>
                                                    <v-col cols="4" class="reccommendation-heading">
                                                        Role 2
                                                    </v-col>
                                                </v-row>
                                                <v-divider/>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row v-for="item,staffId in recommendationData" :key="'R-'+staffId" class="no-boundaries">
                                    <v-col cols="2" class="staff-name">
                                        {{ staff[staffId].firstName }} {{ staff[staffId].lastName }}
                                    </v-col>
                                    <v-divider vertical/>
                                    <v-col cols="10" class="no-boundaries">
                                        <v-row class="no-boundaries">
                                            <v-col v-for="playerId in selectedPlayers" :key="playerId" :cols="12/selectedPlayers.length" class="compare-cell">
                                                <v-row v-if="recommendationData[staffId][playerId]" class="no-boundaries">
                                                    <v-col cols="4" class="compare-cell">
                                                        <div v-if="item[playerId].teamId" class="team-name">
                                                            <span v-if="item[playerId].teamId === 'Discard'">No Offer</span>
                                                            <span v-else >
                                                                {{ teams[item[playerId].teamId].name }}
                                                            </span>
                                                        </div>
                                                        <div v-else>
                                                            -
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="4" class="compare-cell">
                                                        <div v-if="false && item[playerId].role1Id " class="team-name">
                                                                {{ roles[item[playerId].role1Id].name }}
                                                        </div>
                                                        <div v-else>
                                                            -
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="4" class="compare-cell">
                                                        <div v-if="item[playerId].role2Id " class="team-name">
                                                            {{ roles[item[playerId].role2Id].name }}
                                                        </div>
                                                        <div v-else>
                                                            -
                                                        </div>
                                                    </v-col>
                                                </v-row>

                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                               
                                <v-divider></v-divider>

                                <v-row class="no-boundaires">
                                    <v-col cols="2" class="heading-column">
                                        Comments
                                    </v-col>
                                    <v-divider vertical/>
                                    <v-col class="no-boundaries">
                                        <v-row class="no-boundaries">
                                            <v-col v-for="n in selectedPlayers" :key="n" :cols="12/selectedPlayers.length" class="compare-cell">
                                                <v-row class="no-boundaries">
                                                </v-row>
                                                <v-divider/>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row v-for="item, staffId in commentData" :key="staffId" class="no-boundaries">
                                    <v-col cols="2" class="staff-name">
                                        {{ staff[staffId].firstName }} {{ staff[staffId].lastName }}
                                    </v-col>
                                    <v-divider vertical/>
                                    <v-col cols="10" class="no-boundaries">
                                        <v-row class="no-boundaries">
                                            <v-col v-for="n in selectedPlayers" :key="n" :cols="12/selectedPlayers.length" class="comment-cell">
                                                <div class="team-name">
                                                    {{ commentData[staffId][n] }}&nbsp;
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>

                                <v-divider/>
                                <v-row class="no-boundaries">
                                    <v-col cols="2" class="heading-column" style="margin-top:5px;">
                                        Skill Feedback
                                    </v-col>
                                    <v-divider vertical></v-divider>
                                    <v-col cols="10">
                                        <v-row class="no-boundaries">
                                            <v-col v-for="playerId in selectedPlayers" :key="playerId" :cols="12/selectedPlayers.length" class="compare-cell">
                                                <feedbackListLine v-for="(feedback,n) in feedbackByPlayerAndFrequency[playerId]" :staffList="feedback.staff" :feedbackId="n" dense :key="n"/>

                                            </v-col>
                                        </v-row>

                                    </v-col>
                                </v-row>

                            </v-card-text>
                        </v-card>

                    </v-tab-item>
                    <v-tab-item key="2">
                        <v-row class="no-boundaries">
                            <v-col class="no-boundaries" cols="1" style="margin-top:auto;margin-bottom:auto;">
                                <div v-if="pageStart>0">
                                    <v-icon @click="pageStart--">mdi-chevron-left</v-icon>
                                </div>
                            </v-col>
                            <v-col cols="5" sm="3" md="2" lg="2" xl="2" v-for="n in shownTeams" :key="n" class="no-boundaries" style="padding-left:5px;padding-right:5px;">
                                <BoundedColumn v-if="renderTeam(n)" style="display:flex;flex-direction:column" :borderColor="renderTeam(n).color" titleHeight="55px" :titleBackgroundColor="renderTeam(n).color" :titleColor="contrastColor(renderTeam(n).color)">
                                    <div slot="title" style="display:flex;flex-direction:column;flex-grow:1;">
                                        {{ renderTeam(n).name }}
                                        <v-spacer/>
                                        <div style="font-size:x-small" class="text-right"> 
                                            ({{ playersInTeam(renderTeam(n).id)  }} players)
                                        </div> 
                                    </div>
                                    <div slot="content" class="no-boundaries">
                                        <div v-for="roleId in roleList" :key="roleId" class="no-boundaries role-head dropZone" @drop="onDrop($event,renderTeam(n).id, roleId )" @dragover.prevent @dragenter.prevent>
                                            <div :style="`background: ${renderTeam(n).color};color:${contrastColor(renderTeam(n).color)}`" class="role-title no-boundaries " >
                                                {{ roles[roleId].name }}
                                            </div>
                                            <div v-for="id in allocatedPlayers[renderTeam(n).id][roleId]"  :key="id" class="no-boundaries" draggable @dragstart="startDrag($event,{participantId:id,fromTeamId:renderTeam(n).id, fromRoleId:roleId, fromResponse: participants[id].offerResponse} )">
                                                <player-select-button teamSelector :participantId="id" v-on:click.native="selectParticipant(id)" :selected="selectedPlayers.includes(id)"/>
                                            </div>
                                        </div>
                                    </div>
                                </BoundedColumn>
                            </v-col>
                            <v-col class="no-boundaries" cols="1" style="margin-top:auto;margin-bottom:auto;">
                                <div v-if="moreTeams">

                                    <v-icon @click="pageStart++">mdi-chevron-right</v-icon>
                                </div>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
            <v-col cols="4" style="margin-top:0px;padding-right:10px;">
                <v-expansion-panels mandatory height="100%" v-model="activePanel" multiple accordion>
                    <!-- Show the teams that have been selected for this tryout...-->


                    <v-expansion-panel v-for="teamId in availableTeamsNotDiscard" :key="teamId" class="no-boundaries">
                        <v-expansion-panel-header class="text-h8 left-header" >
                            <div :class="responsiveClass('left-headings')" style="display:flex;flex-direction:column;flex-grow:1">
                                {{ teams[teamId].name }}
                                &nbsp;&nbsp;({{ playersInTeam(teamId) }} athletes )
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col class="role-name dropzone" v-for="roleId in roleList" :key="roleId">
                                    {{roles[roleId].name}}
                                </v-col> 
                            </v-row>
                            <v-row style="min-height:40px;">
                                <v-col v-for="roleId in roleList" :key="roleId" class="drop-zone" @drop="onDrop($event, teamId, roleId)" @dragover.prevent @dragenter.prevent :style="`background: ${teams[teamId].color};`">
                                    <div v-for="id in allocatedPlayers[teamId][roleId]" :key="id" draggable @dragstart="startDrag($event, {participantId: id, fromTeamId: teamId, fromResponse: participants[id].offerResponse})">
                                        <player-select-button teamSelector mini :participantId="id" v-on:click.native="selectParticipant(id)" :selected="selectedPlayers.includes(id)"/>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel class="no-boundaries">
                        <v-expansion-panel-header disable-icon-rotate class=" text-h8 dropzone left-header" @drop="onDrop($event, null, null)" @dragover.prevent @dragenter.prevent>
                            <div :class="responsiveClass('left-headings')+' dropzone'" @drop="onDrop($event, null, null)" @dragover.prevent @dragenter.prevent>
                                Waitlist : &nbsp;&nbsp;({{ unallocatedPlayers.length }} athletes)
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="no-boundaries">

                            <v-row class="no-boundaries role-head text-h8 dropzone" @dropover="dropOver()" @drop="onDrop($event, null, null)" @dragover.prevent @dragenter.prevent>
                                <v-col class="no-boundaries" cols="12" sm="6" md="4" lg="4" xl="4"  v-for="id in unallocatedPlayers" :key="id">
                                        <div class="no-boundaries" draggable  @dragstart="startDrag($event,{participantId:id,fromTeamId:0, fromRoleId:0})">
                                            <player-select-button :class="dragFormat" teamSelector :participantId="id" v-on:click.native="selectParticipant(id)" :selected="selectedPlayers.includes(id)"/>
                                        </div>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="text-h8 dropzone left-header" @drop="onDrop($event, 'Discard', null)" @dragover.prevent @dragenter.prevent>
                            <div :class="responsiveClass('left-headings')+' dropzone'" @drop="onDrop($event, null, null)" @dragover.prevent @dragenter.prevent>
                                No Offer : &nbsp;&nbsp;({{ discardPlayers.length }} athletes)
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row class="no-boundaries role-head text-h8 dropzone" @dropover="dropOver()" @drop="onDrop($event, 'Discard', null)" @dragover.prevent @dragenter.prevent>
                                <v-col class="no-boundaries" cols="12" sm="6" md="4" lg="4" xl="4" v-for="id in discardPlayers" :key="id">
                                        <div class="no-boundaries" draggable  @dragstart="startDrag($event,{participantId:id,fromTeamId:0, fromRoleId:0})">
                                            <player-select-button :class="dragFormat" teamSelector :participantId="id" v-on:click.native="selectParticipant(id)" :selected="selectedPlayers.includes(id)"/>
                                        </div>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import Loading from '@/components/loading'
import BoundedColumn from '@/components/BoundedColumn'
import PlayerSelectButton from '@/components/PlayerSelectButton.vue';
import SkillStack from '@/components/SkillStack'

import feedbackListLine from '../../components/feedbackListLine.vue';
//import AthleteCard from '../../components/NewAthleteCard.vue';
import RenderAIReport from '../../components/RenderAIReport.vue';

import ParticipantFilterDialog from '@/components/ParticipantFilterDialog'
import ParticipantProfileImage from '@/components/ParticipantProfileImage'

import {median} from 'mathjs'

export default{
    props: [],
    components:{
        ParticipantProfileImage,
        ParticipantFilterDialog,
        SkillStack,
        Loading,
        BoundedColumn,
        PlayerSelectButton,
        feedbackListLine,
//        AthleteCard,
        RenderAIReport,
    },
    computed: {
        loading(){
            return false ||
                this.roleStatus.loading ||
                this.teamStatus.loading ||
                this.participantStatus.loading ||
                this.commentStatus.loading ||
                this.playerskillStatus.loading ||
                this.criterionStatus.loading ||
                this.attendanceStatus.loading ||
                this.roleStatus.loading || 
                !this.dataReady;
        },
        ...mapState({
            roles:                  state => state.roleStore.data,
            roleList:               state => state.roleStore.list,
            roleStatus:             state => state.roleStore.status,
            rcLinks:                state => state.roleStore.meta.rcLinks,

            teams:                  state => state.teamStore.data,
            teamList:               state => state.teamStore.list,
            teamStatus:             state => state.teamStore.status,

            participants:           state => state.participantStore.data,
            participantList:        state => state.participantStore.list,
            participantStatus:      state => state.participantStore.status,
            displayList:            state => state.participantStore.displayList,

            criterionData:          state => state.criterionStore.data,
            criterionList:          state => state.criterionStore.list,
            criterionStatus:        state => state.criterionStore.status,

            playerskill:            state => state.playerskillStore.data,
            playerskillStatus:      state => state.playerskillStore.status,

            comments:               state => state.commentStore.listByPlayerAndStaff,
            commentStatus:          state => state.commentStore.status,
            commentsByPlayerAndStaff:   state => state.commentStore.listByPlayerAndStaff, 

            selectedTryout:         state => state.navigatorStore.selectedTryout,
            aiStatus:               state => state.aiStore.status,
            aiData:                 state => state.aiStore.data,

            account:                state => state.account,

            attendancePerSession:   state => state.attendanceStore.perSessionLists,
            attendance: state =>    state.attendanceStore.data,
            attendanceStatus:       state => state.attendanceStore.status,

            staff:                  state => state.staffStore.data,
            staffList:              state => state.staffStore.list,
            staffStatus:            state => state.staffStore.status,

            feedbackByPlayerAndFrequency:   state => state.feedbackLinkStore.listByPlayerAndFrequency,
            feedbackByPlayerAndCriterion:   state => state.feedbackLinkStore.listByPlayerAndCriterion,
            feedback:               state => state.feedbackStore.data,

            teamRecommendations:    state => state.teamrecommendationStore.data,
            recommendationsByTeamAndPlayer: state => state.teamrecommendationStore.recommendationsByTeamAndPlayer,
            recommendationsByPlayer: state => state.teamrecommendationStore.recommendationsByPlayer,
            recommendationsByStaff:  state => state.teamrecommendationStore.recommendationsByStaff,
            recommendationStatus:   state => state.teamRecommendationStore.status,
        }),
        availableRoles(){
            const list=[{text:'General', value: 0}]
            const keys = Object.keys(this.rcLinks)
            for(var i in keys){
                if(this.rcLinks[keys[i]].length>0){
                    list.push({text: this.roles[keys[i]].name, value: this.roles[keys[i]].id })
                }

            }
            return list

        },
        recommendationData(){
            const list = {}

            for(var idx in this.staffList){
                const staffId = this.staffList[idx]
                const data={}
                for(var idx2 in this.selectedPlayers){
                    const playerId = this.selectedPlayers[idx2]
                    const subdata={}
                    if(this.recommendationsByStaff[staffId] && this.recommendationsByStaff[staffId][playerId] && (
                        (this.recommendationsByStaff[staffId][playerId].teamId && this.recommendationsByStaff[staffId][playerId].teamId!='') ||
                        (this.recommendationsByStaff[staffId][playerId].role1Id) ||
                        (this.recommendationsByStaff[staffId][playerId].role2Id))) {
                            subdata.teamId = this.recommendationsByStaff[staffId][playerId].teamId
                            subdata.role1Id = this.recommendationsByStaff[staffId][playerId].role1Id?this.recommendationsByStaff[staffId][playerId].role1Id:null
                            subdata.role2Id = this.recommendationsByStaff[staffId][playerId].role2Id?this.recommendationsByStaff[staffId][playerId].role2Id:null
                       data[playerId]=subdata
                    }
                }
                if(Object.keys(data).length >0){
                    list[staffId]=data
                }
            }
            return list
        },
        commentData(){
            const list={}

            for(var idx in this.staffList){
                const staffId = this.staffList[idx]
                const data={}
                for(var idx2 in this.selectedPlayers){
                    const playerId = this.selectedPlayers[idx2]
                    if(this.commentsByPlayerAndStaff[playerId][staffId]){
                        data[this.selectedPlayers[idx2]]=this.commentsByPlayerAndStaff[playerId][staffId]
                    }
                }
                if(Object.keys(data).length!==0){
                    list[staffId]=data
                }
            }
            return list
        },
        weightedCriterionLists() {
            const weights={}
            if(this.selectedWeighting === 0) {
                for(var i in this.criterionList){
                    weights[this.criterionList[i]] = 1
                }
            } else {
                for(i in this.criterionList){
                    weights[this.criterionList[i]] = 0
                }
                for(i in this.rcLinks[this.selectedWeighting]){
                    weights[this.rcLinks[this.selectedWeighting][i].criterionId] = this.rcLinks[this.selectedWeighting][i].weight
                }
            }
            return {weights: weights, weightedList:this.criterionList.toSorted((a,b)=>weights[b]-weights[a]).filter((criterionId)=>weights[criterionId]>0)}
        },
        weightedCriterionList(){
            const weightedLists = this.weightedCriterionLists
            return weightedLists.weightedList
        },
        tableHeaders(){
            const headers=[]
            if(this.dataReady){
     
                headers.push({ text: "Athlete", align: 'start', sortable:false, value: 'participantName' })
                headers.push({ text: "Rank", align:"center", sortable: true, value: 'rank' })
                headers.push({ text: 'Weighted Score', align: 'center', sortable: true, value: 'weightedScore' })

                const weightedList= this.weightedCriterionList
                for(var idx in weightedList){
                    const criterionId = weightedList[idx]
                    headers.push({
                        text: this.criterionData[criterionId].description+' ('+ this.medians[criterionId].toFixed(1) +')',
                        align: 'center',
                        value: criterionId+'A'
                    })
                }

            }
            return headers

        },
        medians(){
            const weightedList = this.weightedCriterionList
            const mediansData={}
            const medians = {}
            
            // Calculate the medians for all criteria first... 

            for(var idx in this.participantList){
                const participantId = this.participantList[idx]
                for(var idx2 in weightedList){
                    const criterionId= weightedList[idx2]
                    if(!mediansData[criterionId]) mediansData[criterionId]=[]
                    if(this.playerskill){
                        if(this.playerskill[participantId].criteria[criterionId].average>0){
                            mediansData[criterionId].push(this.playerskill[participantId].criteria[criterionId].average)
                        }
                    }
                }
            }
            for(var criterionId in mediansData){
                if(mediansData[criterionId].length>0){
                    medians[criterionId] = median(mediansData[criterionId])
                } else {
                    medians[criterionId] = 0;
                }
            }
            return medians
        },
        tableData(){
            const data=[]

            const {weights, weightedList} = this.weightedCriterionLists
            const weightsList=[]
            
            for(var idx in this.displayList){
                const participantId = this.displayList[idx]
                if(this.participants[participantId]){
                    const row={
                        participantNumber: this.participants[participantId].participantNumber,
                        participantName: `${this.participants[participantId].firstName} ${this.participants[participantId].lastName}`,
                        role:this.participants[participantId].prefRole1,
                        participantId: participantId,
                    }
                    var weightedScore = 0
                    var scoredCriteria = 0;
                    var numCriteria = weightedList.length


                    

                    for (var idx2 in weightedList){
                        const criterionId = weightedList[idx2]
                   //     if(!this.medians[criterionId]) this.medians[criterionId]=[]
                    
                        if(this.playerskill){
                            const average = this.playerskill[participantId].criteria[criterionId].average
                            const complete = this.playerskill[participantId].criteria[criterionId].complete
                            row[criterionId+'A'] = average
                            row[criterionId] = {
                                average: average,
                                complete: complete,
                                median: this.medians[criterionId]
                            }

                            var thisScore=0
                            if(this.playerskill[participantId].criteria[criterionId].average>0){
                                // Take the average swcore for this criteria, and scale it back to the median score based on the number of assessors
                                
                                const confidence = complete/100
                                const gap = this.medians[criterionId]-average
                                const diff = gap * confidence
                                
                                thisScore = this.medians[criterionId] - diff

                                // apply the selected weighting

                                thisScore = average * weights[criterionId] 

                                scoredCriteria++
                            } else {
                                //weightedScore += this.medians[criterionId]
                                thisScore += 0
                            }
                            weightedScore += thisScore

                        } else {
                            row[criterionId]=0
                        }
                    }
                    weightedScore *= scoredCriteria/numCriteria

                    if(this.participants[participantId].offer==='Discard') {
                        //weightedScore=0
                        row['offer'] = 'No Offer'
                    } else if(this.participants[participantId].offer){
                        row['offer'] = this.teams[this.participants[participantId].offer].name

                    } else {
                        row['offer'] = 'Waitlist'
                    }
                    row['weightedScore'] = weightedScore
                    data.push(row)
                    weightsList.push(weightedScore)
                }
            }

            weightsList.sort((a,b)=> b-a)
            for(idx in data){
                data[idx]['rank'] = weightsList.indexOf(data[idx].weightedScore)+1
            }



            return data

        },
        shownTeams(){
            if(this.$vuetify.breakpoint.xs) return 2
            else if (this.$vuetify.breakpoint.sm) return 3
            else if (this.$vuetify.breakpoint.md) return 5
            else return 5
        },
        availableTeamsNotDiscard(){
            return this.availableTeams.filter((teamId)=>teamId!=='Discard')
        },
        lastOffer(){
            if(!this.participants[this.selectedPlayers[0]].discardHistory) return ""
            return this.participants[this.selectedPlayers[0]].discardHistory.split('/')[0]
        },
        lastRole(){
            if(!this.participants[this.selectedPlayers[0]].discardHistory) return ""
            return this.participants[this.selectedPlayers[0]].discardHistory.split('/')[1]
        },
        lastResponse(){
            if(!this.participants[this.selectedPlayers[0]].discardHistory) return ""
            return this.participants[this.selectedPlayers[0]].discardHistory.split('/')[2]
        },
        availableTeams(){
            var list = this.teamList.filter(this.filterTryoutTeams)
            var head = ["Discard"].concat(list)
            return head
        },
        moreTeams(){
            return this.pageStart+this.shownTeams < this.availableTeams.length
        },
        unallocatedPlayers(){
            return this.allocatedPlayers["Waitlist"]
        },
        discardPlayers(){
            return this.allocatedPlayers['Discard']
        },


    },
    methods: {
        ...mapActions('teamStore', {
            findAllTeamsForEvent: 'findAllForEvent'
        }),
        ...mapActions('roleStore',{
            findRolesForEvent: 'findAllForEvent',
        }),
        ...mapActions('participantStore', {
            findAllParticipantsForEvent: 'findAllForEvent',
            updateParticipantProp: 'propertyUpdate',
            updateParticipant: 'update',
        }),
        ...mapActions('commentStore', {
            findAllCommentsForEvent: 'findAllForEvent',
        }),
        ...mapActions('playerskillStore', {
            findAllPlayerSkillScores: 'findAllForEventAndStaff',
            quietFindAllPlayerSkillScores: 'quietFindAllForEventAndStaff'
        }),
        ...mapActions('criterionStore', {
            findAllCriterion : 'findAllForEvent'
        }),
        ...mapActions('attendanceStore', {
            findAllAttendanceForEvent: 'findAllForEvent',
            updateAttendanceState: 'propertyUpdate',
            updateAttendance: 'update'
        }),
        ...mapActions('teamrecommendationStore', {
            loadRecommendations: 'findAllForEvent',
        }),
        ...mapActions('staffStore', {
            findAllStaff: 'findAllForEvent',
        }),
        ...mapActions('feedbackStore', [
            'loadAllFeedbackForEvent',
        ]),
        ...mapActions('feedbackLinkStore', [
            'loadAllFeedbackLinksForEvent',
        ]),
        ...mapActions('aiStore',[
            'generateFeedback'
        ]),

        role1(participantId){
            if(!participantId || !this.participants[participantId] || participantId ===0 || participantId === 'null') return null
            if(!this.participants[participantId].prefRole1) return null
            return this.roles[this.participants[participantId].prefRole1].name
        },
        role2(participantId){
            if(!participantId || !this.participants[participantId] || participantId ===0 || participantId === 'null') return null
            if(!this.participants[participantId].prefRole2) return null
            return this.roles[this.participants[participantId].prefRole2].name
        },
        completionColor: function(complete) {
            const c = Math.floor(complete / 25)
            return this.progressColor[c] 
        },
        clickRow(item, ){
            this.selectParticipant(item.participantId)
        },
        commentsForPlayer(playerIdx){
            const commentArray=[]

            for(var idx in this.commentsByPlayerAndStaff[this.selectedPlayers[playerIdx]]){
                if(this.staff[idx]){
                    commentArray.push({
                        staff: `${this.staff[idx].firstName} ${this.staff[idx].lastName}`,
                        comment: this.commentsByPlayerAndStaff[this.selectedPlayers[playerIdx]][idx]
                    })
            }
            }
            return commentArray
        },
        recordedAttendance(idx){
                    const sessionsAttended = this.attendancePerSession[0][this.selectedPlayers[idx]]
                    const sessions = Object.keys(this.attendancePerSession).length-1
                    return `${sessionsAttended}/${sessions}`
        },
        recommendationsForPlayer(playerIdx){
            const recommendationArray=[]

            for(var idx in this.recommendationsByPlayer[this.selectedPlayers[playerIdx]]){
                const recId =this.recommendationsByPlayer[this.selectedPlayers[playerIdx]][idx] 
                const staffId = this.teamRecommendations[recId].staffId
                recommendationArray.push({
                    staff: `${this.staff[staffId].firstName} ${this.staff[staffId].lastName}`,
                    team:  this.teamRecommendations[recId].teamId?(this.teamRecommendations[recId].teamId==='Discard'?"No Offer":this.teams[this.teamRecommendations[recId].teamId].name):'',
                    role1: this.teamRecommendations[recId].role1?this.roles[this.teamRecommendations[recId].role1].name:'', 
                    role2: this.teamRecommendations[recId].role2?this.roles[this.teamRecommendations[recId].role2].name:'', 

                })
            
            }
            return recommendationArray

        },
        responsiveClass(className){
            if(this.$vuetify.breakpoint.smAndDown){
                return className+' mobile'
            }
            return className
        },
        playerName(idx){
            if(!this.selectedPlayers[idx]) return ""
            return this.participants[this.selectedPlayers[idx]].firstName + ' ' + this.participants[this.selectedPlayers[idx]].lastName
        },
        
        contrastColor(hex) {
            // Y = 0.2126 R + 0.7152 G + 0.0722 B
            // S = (max(R, G, B) - min(R, G, B)) / max(R, G, B)

            // The second one is the calculation from the HSV colour space.

            // If the saturation is low enough (pick any value you like; something 
            // between 0.3 and 0.5 would work fine), check the luminance; 
            // if that's > 0.5, your contrasting colour is black, else if 
            // it's < 0.5 the colour is white. For exactly Y = 0.5, both work.


            if (hex.indexOf('#') === 0) {
                hex = hex.slice(1);
            }
            if (hex.length === 3) {
                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
            }
            const red = Number('0x'+hex[0]+hex[1])/255
            const green = Number('0x'+hex[2]+hex[3])/255
            const blue = Number('0x'+hex[4]+hex[5])/255

            const gamma = 2.2
            const L = 0.2126 * Math.pow( red, gamma ) + 0.7152 * Math.pow( green, gamma ) + 0.0722 * Math.pow( blue, gamma );
            

            const use_black = ( L > Math.pow( 0.5, gamma ) );

            return use_black?'#000000FF':'#FFFFFFFF'


        },

        filterTryoutTeams(value){
            return this.teams[value].selected
        },
        renderTeam(index){
            // Remember index is going to be one off...
            if(index>this.availableTeams.length){
                return null
            } else {
                return this.teams[this.availableTeams[index-1+this.pageStart]]

            }
        },
        playersInTeam(teamId){
            return this.displayList.filter((id)=>{
                return this.participants[id] && this.participants[id].offer===teamId}
            ).length
        } ,
        selectParticipant(id){
            const idx = this.selectedPlayers.indexOf(id)
            if (idx === -1) {
                // Bump out the oldest element and add this one.
                if(this.selectedPlayers.length === 2)
                    this.selectedPlayers.shift()
                this.selectedPlayers.push(id)
            } else {
                this.selectedPlayers.splice(idx,1)
            }
        },
        startDrag(event, payload){
            const {participantId, fromTeamId, fromRoleId, fromResponse} = payload

            event.dataTransfer.dropEffect="move"
            event.dataTransfer.effectAllowed="move"   
            event.dataTransfer.setData('participantId', participantId)
            event.dataTransfer.setData('fromTeamId', fromTeamId)
            event.dataTransfer.setData('fromRoleId', fromRoleId)
            event.dataTransfer.setData('fromResponse', fromResponse )

            this.dragFormat="opacity:25%;"
        },

        async onDrop(evt, teamId, roleId){
            const participantId=evt.dataTransfer.getData('participantId')
            const fromTeamId=evt.dataTransfer.getData('fromTeamId')
            const fromRoleId=evt.dataTransfer.getData('fromRoleId')
            const fromResponse=evt.dataTransfer.getData('fromResponse')

            if(fromTeamId === teamId && fromRoleId === roleId){
                return
            }
            

            var discardHistory = null
            if(teamId==='Discard'){
                discardHistory = `${fromTeamId}/${fromRoleId}/${fromResponse}` 
            } else {
                discardHistory=null
            }

            await this.updateParticipantProp({
                field: 'discardHistory',
                id: participantId,
                value: discardHistory
            })
            await this.updateParticipantProp({
                field: 'offer',
                id: participantId,
                value: teamId
            })
            await this.updateParticipantProp({
                field: 'offerRole',
                id: participantId,
                value: roleId
            })
            await this.updateParticipantProp({
                field: 'offerResponse',
                id: participantId,
                value: null,
            })

            await this.updateParticipant(this.participants[participantId])

            this.selectedPlayer=[]
//            this.activePanel=1
            this.resetAllocatedPlayers()
        },
        dropOver(){
            this.dragFormat="opacity:100%;"
        },
        resetAllocatedPlayers(){
            this.dataReady=false
            this.coreResetAllocatedPlayers()
            this.dataReady=true
        },
        coreResetAllocatedPlayers(){

            this.allocatedPlayers={
                    "Waitlist":[],
                    "Discard": [],
            }

            this.progress=10
            this.progressMessage = "Finding available teams"

            for (var idx in this.availableTeams){
                const teamId = this.availableTeams[idx]
                if(teamId !=='Discard'){

                    this.allocatedPlayers[teamId]={}
                    for (var roleId in this.roles){
                        this.allocatedPlayers[teamId][roleId]=[]
                    }
                }
            }


            const progSteps = this.displayList.length
            var progCounter=0
            for(idx in this.participantList){
                const participantId = this.participantList[idx]
                if(this.participants[participantId] && this.participants[participantId].offer==='Discard'){
                    this.allocatedPlayers['Discard'].push(participantId)
                }
            }

            for(var idx2 in this.displayList){

                progCounter++
                this.progress = 20+((progCounter/progSteps)*80)
                const participantId = this.displayList[idx2]
                
                if(this.participants[participantId] && !this.participants[participantId].archived && this.participants[participantId].offer !== 'Discard'){
                    // We have already treated discarded athletes
                    if(this.participants[participantId] && this.participants[participantId].offer){
                        if(!Object.prototype.hasOwnProperty.call(this.allocatedPlayers, this.participants[participantId].offer)){
                            this.allocatedPlayers[this.participants[participantId].offer]={}
                        }
                        if(this.participants[participantId].offerRole){
                            if(!Object.prototype.hasOwnProperty.call(this.allocatedPlayers[this.participants[participantId].offer],this.participants[participantId].offerRole)){
                                this.allocatedPlayers[this.participants[participantId].offer][this.participants[participantId].offerRole]=[]
                            }
                            this.allocatedPlayers[this.participants[participantId].offer][this.participants[participantId].offerRole].push(participantId)
                        } else {
                            this.allocatedPlayers["Waitlist"].push(participantId)    
                        }
                    } else {
                        this.allocatedPlayers["Waitlist"].push(participantId)    
                    }
                } 
            }
        },
        async genFeedback(playerId){

            const scoreList={criteria: {}}
            var feedbackList=[]
            // Find all criteria > 0 and capture the scores

            for(var idx in this.criterionList){
                const criterionId = this.criterionList[idx]
                if(this.playerskill[playerId].criteria[criterionId].average>0){
                    if (!scoreList.criteria[criterionId]) scoreList.criteria[criterionId]={}
                    scoreList.criteria[criterionId].average = this.playerskill[playerId].criteria[criterionId].average

                    // Grab any feedback associated with this criterion.
                
                    if(this.feedbackByPlayerAndCriterion[playerId] && this.feedbackByPlayerAndCriterion[playerId][criterionId]){
                        scoreList.criteria[criterionId].feedbackList = this.feedbackByPlayerAndCriterion[playerId][criterionId]
                        const newArray = feedbackList.concat(this.feedbackByPlayerAndCriterion[playerId][criterionId])
                        feedbackList = newArray
                    }
                }
            } 
            scoreList.feedbackList = feedbackList

            this.generateFeedback({
                eventId: this.selectedTryout,
                participantId: playerId,
                scoreList: scoreList,
            })
            /*
            .then(response => {

            }
            */
        },
        async loadData(){
            try{

                this.dataReady=false
                await this.findAllParticipantsForEvent(this.selectedTryout) 
                this.progress=20
                this.progressMessage = "Lining up all the trialists"
                await this.findRolesForEvent({eventId: this.selectedTryout})
                this.progress=30
                this.progressMessage = "Looking at all the comments that have been captured"
                await this.findAllCommentsForEvent({eventId: this.selectedTryout})
                this.progress=50
                this.progressMessage = "Fetching all the scores for the trialists"
                await this.findAllPlayerSkillScores({eventId: this.selectedTryout, userId: this.account.user.details.id})
                await this.findAllCriterion(this.selectedTryout)
                this.progress=70
                this.progressMessage = "Calling for recommendations"
                await this.loadRecommendations ({eventId: this.selectedTryout})
                await this.findAllStaff({eventId: this.selectedTryout})
                this.progress=90
                this.progressMessage = "Loading any feedback given"
                await this.findAllAttendanceForEvent({eventId: this.selectedTryout})
                await this.loadAllFeedbackForEvent({eventId: this.selectedTryout})
                await this.loadAllFeedbackLinksForEvent({eventId: this.selectedTryout})

                await this.findAllTeamsForEvent({eventId: this.selectedTryout})

                this.coreResetAllocatedPlayers()
                this.dataReady=true

            } catch(error) {
                console.error('Error in NewTeamSelector::loadData')
                console.error(error)
            }
        },
        scoreStyle(score){
            return this.iconColor[Math.ceil(score)]
        }

    },
    mounted() {
        this.$store.subscribeAction({
            // eslint-disable-next-line
            after: (action, state) => {
                if (action.type === "participantStore/SOCKET_ParticipantUpdated" ) {

                    this.resetAllocatedPlayers()
                }
            }
        });
        this.$socket.emit('join-room', `teamselect-${this.selectedTryout}`, localStorage.getItem('jwt'))
        this.$socket.emit('join-room', `data-participant-${this.selectedTryout}`,localStorage.getItem('jwt'))
        this.$root.$on('tryoutRefresh', this.refreshHandler)
        this.$root.$on('displayListChanged', this.displayListChangedHandler)
    },
    sockets:{
        connect(){
            this.$socket.emit('join-room', `teamselect-${this.selectedTryout}`, localStorage.getItem('jwt'))
            this.$socket.emit('join-room', `data-participant-${this.selectedTryout}`, localStorage.getItem('jwt'))
            this.resetAllocatedPlayers()
        },        
        'UpdateTeamSelections'(){
            this.resetAllocatedPlayers()
        }
    },
    beforeDestroy(){
        this.$socket.emit('leave-room', `teamselect-${this.selectedTryout}`)
        this.$socket.emit('leave-room', `data-participant-${this.selectedTryout}`)
        this.$root.$off('tryoutRefresh', this.refreshHandler)
        this.$root.$off('displayListChanged', this.displayListChangedHandler)
    },

    async created(){
        await this.loadData()
    },
    data(){
        return {
            progress: 0,
            progressMessage: 'Getting Event Data',
            displayListChangedHandler: ()=>{this.resetAllocatedPlayers();},
            sortColumns:[],
            sortBy:['rank'],
            progressColor : [
                "red",
                "red",
                "orange",
                "light-green accent-3",
                "light-green accent-4"
            ],
            iconColor: [
                "gray",
                "red",
                "orange",
                "yellow",
                "lime",
                "light-green accent-3"
            ],
            refreshHandler: async()=>{
                await this.loadData()
            },
            pageTab:0,
            setStateDialog: false,
            pageStart: 0,
            selectedPlayers: [],
            allocatedPlayers: {"Waitlist":[]},
            dataReady: false,
            dragFormat: "opacity:50%",
            activePanel: [],
            updateFlag:1,
            states: [
                {label:"Not Made", value:""},
                {label:"Offer Made", value:"sent"},
                {label:"Offer Accepted", value:'accept'},
                {label:'Offer Declined', value:'decline'}
            ],
            showReview: false,
            selectedWeighting: 0,
        }
    }

}
</script>

<style scoped>

tbody{
    overflow-y:scroll
}
.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
    padding:0px;
}

.v-expansion-panel-content__wrap {
    padding:0px !important;
}

.player-info{
    background: var(--cs2-lev1);
    border-radius: 10px;
    margin:10px;
    display:flex;
    flex-flow:row;
    height:100px;
    padding:5px;
}
.role-title {
    font-size:small;
    color:white;
    text-align: left;
    margin-bottom: 5px;
    margin-left:5px;
    font-weight:600;
    font-size:x-small;
    font-style:italic;
}
.drag-el{
    background-color: #fff;
    opacity:50%;
}

.role-head{
    border-top-color:white;
    border-top-width:1px;
    border-top-style:solid;
}

.page-title{
    font-size:medium;
    font-weight:900;
    margin-left:10px;
    text-align:left;
    margin-top:10px;
    margin-bottom:0px;
}

.left-headings{
    font-size: small;
    font-weight:500;
}

.left-headings.mobile{
    font-weight:600;
    font-size: x-small;
}

.left-header {
    padding-left:5px;
    padding-right:0px;
}
.heading-column{
    margin-top: auto;
    margin-bottom:auto;
    /*
    margin-left:5px;
    margin-right:5px;
    */
    padding:0px;
    font-family: 'Varela Round';
    font-weight:600;

}
.staff-name{
    font-size: small;
    margin:0px;
    padding:0px;
    text-align: right;
    padding-right:5px;
}
.recommendation-heading{
    font-size:small;
    font-weight:600;
    font-family: 'Varela-Round';
    margin:0px;
    padding:0px;
    border-width: thin;
    border-right-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
}
.team-name{
    font-size:small;
    margin:0px;
    padding:0px;
}
tr.v-data-table__selected {
    background: lightgreen !important;
}
.selected-row{
    background: lightgreen !important;

}
.compare-cell{
    border-width: thin;
    border-right-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
    margin:0px;
    padding:0px;
}
.comment-cell{
    border-width: thin;
    border-right-style: solid;
    border-bottom-style: dotted;
    border-color: rgba(0,0,0,0.12);
    margin:0px;
    padding:0px;

}
.player-number{
    border-radius: 5px;
    background: blue;
    color: white;
    font-family: "Varela Round";
    font-weight: 600;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}
.role-name{
    margin: 0px;
    padding: 0px;
    font-size: xx-small;

}
.drag-el{
    background-color: #fff;
}
.drop-zone{
    background-color: #eee;
    margin-bottom:px;
    height: inherit;
    margin: 0px;
    padding: 0px;
    border: 1px solid black;
}
.role-pill{
    color: white;
    border-radius: 10px;
    width: 100px;

    background: #fff;
    border: 2px solid #666;
    color: #666;
    text-align: center;

    font-size:  0.8em;
    font-weight:600;
    font-family: 'Varela Round';
}
.role-wrapper{
    font-size: 0.8em;
    color:white;
    font-weight:600;
    margin-left:15px;
}
.offer-text{
    font-size:0.7em;
    font-weight:600;
}
</style>