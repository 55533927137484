<template>
    <loading v-if="loading"/>
    <div style="width:100%" v-else>
        <v-dialog v-model="reasonDialog" max-width="60%">
            <v-card>
                <v-card-title>Absence Record</v-card-title>
                <v-card-text>
                    <v-text-field label="Absence Reason" v-model="reason"/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="blue darken-1" text @click="closeReasonDialog">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="saveReason">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                    v-model="showDialog"
                    persistent
                    max-width="600px"
                    width="60%"
                >
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">New Participant</span> 
                        </v-card-title> 
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col 
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-text-field 
                                            label="First Name*"
                                            required
                                            :rules="[rules.name, rules.length]"
                                            v-model="firstName"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-text-field 
                                            label="Last Name*"
                                            :rules="[rules.name, rules.length]"
                                            required
                                            v-model="lastName"
                                        ></v-text-field>
                                    </v-col>

                                </v-row> 
                            </v-container> 

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="closeDialog" 
                            >
                                Close
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="saveUser" 
                            >
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                
                </v-dialog>
        <v-row>
            <v-col cols="6" sm="6" md="4" lg="4" xl="4">
                <div class="pane-heading">
                    Participant List
                </div>
            </v-col>
            <v-col v-responsive.xs.sm cols="6" style="text-align:right;display:flex;justify-content:right;flex-flow:column;">
                <v-btn 
                    color="primary"
                    dark
                    x-small
                    @click="showDialog=true"
                >
                    Add New Participant
                </v-btn>
                <v-btn
                    color="primary"
                    dark
                    x-small
                    @click="exportData"
                >Export Data</v-btn>



            </v-col>
            <v-spacer/>
            <v-col v-responsive.md.lg.xl cols="4" style="display:flex;flex-flow:row">
                        <v-spacer/>
                        <v-btn 
                            color="primary"
                            dark
                            x-small
                            @click="showDialog=true"
                        >
                            Add New Participant
                        </v-btn>
                <v-btn
                    color="primary"
                    dark
                    x-small
                    style="margin-left:5px;"
                    @click="exportData"
                >Export Data</v-btn>
                   
            </v-col>

        </v-row>

        <v-row :key="updateKey">
            <v-col cols="12">
                <v-data-table
                    :headers="participantHeaders"
                    :items="participantArray"
                    :items-per-page="-1"
                    class="elevation-1"
                    fixed-header
                    height="70vh"
                    mobile-breakpoint="10" 
                >
                    <template v-if="this.$vuetify.breakpoint.mdAndUp" v-slot:top="{ pagination, options, updateOptions }">
                        <v-data-footer 
                        :pagination="pagination" 
                        :options="options"
                        @update:options="updateOptions"
                        items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
                    </template>
                    <template v-slot:item.participantNumber="{item}">
                        <span :class="[item.archived?'strikeout':'']">{{ item.participantNumber }}</span>
                    </template>
                    <template v-slot:item.firstName="{item}">
                        <span :class="[item.archived?'strikeout':'']">{{ item.firstName }}</span>
                    </template>
                    <template v-slot:item.lastName="{item}">
                        <span :class="[item.archived?'strikeout':'']">{{ item.lastName }}</span>
                    </template>
                    <template v-slot:item.profileImage="{item}">
                        <v-tooltip right>
                            <template v-slot:activator="{on, attrs}">
                                <v-img v-if="imageAllowed(item.id)" v-on="on" v-bind="attrs" :src="item.profileImage" max-height="20px" max-width="20px"/>
                                <v-icon v-else size="20px">mdi-account-outline</v-icon>
                            </template>
                            <span>
                                <v-img v-if="imageAllowed(item.id)" :src="item.profileImage" max-height="200px" max-width="200px"/>
                                <v-icon v-else size="200px">mdi-account-outline</v-icon>
                            </span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.deskProcessed="{item}">
                        <v-checkbox :value="item.deskProcessed" v-model="item.deskProcessed" v-on:change="pToggle(item.id)" :disabled="enableFor(['admin','owner','support'])"/>
                    </template>
                    <template v-slot:item.attended="{item}">
                        <span v-if="selectedSession===0 || selectedSession==='0'">{{ item.attended }}</span>
                        <v-checkbox v-else-if="item.attended === -1" indeterminate :disabled="enableFor(['admin','owner','support'])" v-on:change="aToggle(item.id)"/>
                        <v-checkbox v-else v-model="item.attended" v-on:change="aToggle(item.id)" :disabled="enableFor(['admin','owner','support'])" />
                    </template>
                    <template v-slot:item.absenceReason="{item}">
                        <span v-if="item.attended===1" :class='{strike:true}'>{{item.absenceReason}}</span>
                        <span v-else>{{item.absenceReason}}</span>
                    </template>
                    <template v-slot:item.viewApplication="{item}">
                        <review-popup  :participantId="item.id"/>
                    </template>
                    <template v-slot:item.withdrawToggle="{item}">
                        <v-checkbox :disabled="enableFor(['admin', 'owner', 'support'])" v-model="item.archived" v-on:change="toggle(item.id)"/>
                    </template>
                    <template v-slot:item.excludeToggle="{item}">
                        <v-checkbox :disabled="enableFor(['admin', 'owner'])" v-model="item.bypassCollection" v-on:change="eToggle(item.id)"/>

                    </template>"
                </v-data-table>

            </v-col>
        </v-row>
  
    </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex';
import Loading from '@/components/loading'
import ReviewPopup from '@/components/ReviewPopup'
import { v4 as uuidv4} from 'uuid'
export default {
    props: [],
    components: {
        Loading,
        //eslint-disable-next-line
        ReviewPopup,
    },
    computed: {
        ...mapState({
            participants: state => state.participantStore.data,
            participantList: state => state.participantStore.list,
            participantStatus: state => state.participantStore.status,
            teams: state => state.teamStore.data,
            teamStatus: state => state.teamStore.status,
            tryouts: state => state.tryoutStore.data,
            tryoutList:     state => state.tryoutStore.list,
            tryoutStatus: state => state.tryoutStore.status,

            appQuestions: state => state.appquestionStore.data,
            appQuestionStatus: state => state.appquestionStore.status,

            qresponses : state => state.qresponseStore.data,
            qresponseList: state => state.qresponseStore.list,
            qresponseStatus: state => state.qresponseStore.status,

            selectedTryout: state => state.navigatorStore.selectedTryout,
            selectedSeason: state => state.navigatorStore.selectedSeason,
            selectedSession: state => state.navigatorStore.selectedSession,

            userData: state => state.userStore.all,

            sessions: state => state.sessionStore.data,
            sessionList: state => state.sessionStore.list,

            attendancePerSession: state => state.attendanceStore.perSessionLists,
            attendance: state => state.attendanceStore.data,
            attendanceStatus: state => state.attendanceStore.status,
        }),
        loading: function () {
            return this.preparingData
            /*
            return this.participantStatus.loading 
            || this.teamStatus.loading 
            || this.preparingData 
            || this.tryoutStatus.loading 
            || this.qresponseStatus.loading
            || this.appQuestionStatus.loading
            || this.attendanceStatus.loading
            */
        },
        alphaParticipantList: {
            get() {
                let alphaSort = this.participantList;

                alphaSort.sort((a,b) => {
                    return this.participants[a].lastName.localeCompare(this.participants[b].lastName)
                }) 
                return alphaSort
            }
        },
        numParticipantList: {
            get(){
                let numSort = this.participantList;

                numSort.sort((a,b) => {
                    return this.participants[a].participantNumber < this.participants[b].participantNumber
                })
                return numSort
            }
        },
        participantArray: function () {
            if(this.updateKey!=='111'){
                var results=[]
                for(var i=0;i<this.numParticipantList.length;i++){
                    console.log(`Loading? ${this.loading}`)
                    console.log(this.participants)
                    console.log(i)
                    console.log(this.participantList)
                    console.log(this.participantList[i])
                    results[i] = this.participants[this.participantList[i]]
                    console.log('Results')
                    console.log(results[i])
                    if(this.selectedSession !== 0 && this.selectedSession !== '0'){
                        console.log('Checking Attendance')
                        const attId = this.attendancePerSession[this.selectedSession][this.participantList[i]]
                        
                        results[i].attended = this.attendance[this.attendancePerSession[this.selectedSession][this.participantList[i]]].attended
                        results[i].absenceReason = this.attendance[attId].absenceReason
                    } else {
                        const sessionsAttended = this.attendancePerSession[0][this.participantList[i]]
                        const sessions = Object.keys(this.attendancePerSession).length-1
                        results[i].attended=`${sessionsAttended}/${sessions}`
                        results[i].absenceReason=''
                    }
                }
                return results
            } else {
                return []
            }

        },
        sessionNameList: function() {
            const results=[{id: 0, name: 'All Sessions'}]
            for(var i=0;i<this.sessionList.length;i++){
                results.push({
                    id: this.sessionList[i],
                    name: this.sessions[this.sessionList[i]].title
                })
            }
            return results
        },
    },
    methods: {
        ...mapActions('userStore', {
            manualUserCreate: 'createManual'
        }),
        ...mapActions('participantStore', {
            findAllParticipantsForEvent: 'findAllForEvent',
            updateParticipantProperty: 'propertyUpdate',
            updateParticipant: 'update',
            withdrawParticipant: 'archive',
            reinstateParticipant: 'unarchive',
            createManualParticipant: 'addManual',
            updateLocalParticipant: 'updateLocal',
        }),
        ...mapActions('teamStore', {
            findAllTeamsForEvent: 'findAllForEvent',
        }),
        ...mapActions('qresponseStore', {
            findAllResponsesForEvent: 'findAllForEvent',
        }),
        ...mapActions('appquestionStore', {
            findAllQuestionsForEvent: 'loadAllQuestionsForEvent'
        }),
        ...mapActions('tryoutStore', {
            getAllTryoutsForSeason: 'findAllForSeason',
        }),
        ...mapActions('sessionStore', {
            findAllSessionsForEvent: 'findAllForEvent',
        }),
        ...mapActions('attendanceStore', {
            findAllAttendanceForEvent: 'findAllForEvent',
            updateAttendanceState: 'propertyUpdate',
            updateAttendance: 'update',
            updateLocalAttendance: 'localUpdate'
        }),
        imageAllowed: function(playerId){
            if(playerId && playerId !== 0){
                if(this.participants[playerId].junior){
                    return this.participants[playerId].imageConsent
                } else {
                    return true
                }
            }
            return false
        },
        closeDialog(){
            this.firstName=''
            this.lastName=''
            this.participantNumber=''
            this.email=''
            this.phone=''
            this.showDialog = false
        },
        toggle(id){
            if(this.participants[id].archived) {
                this.withdrawParticipant({participantId: id})
            } else {
                this.reinstateParticipant({participantId: id})
            }
        },
        eToggle(id){
           // this.updateParticipantProperty({field:'bypassCollection', id: id, value: !this.participants[id].bypassCollection})
            this.updateParticipant(this.participants[id])
        },
        pToggle(id){

            if(!this.participants[id].deskProcessed){
                this.updateParticipantProperty({field: "deskProcessed", id: id, value: 0}) 
            } else {
                this.updateParticipantProperty({field: "deskProcessed", id: id, value: 1}) 
            }
            this.updateParticipant(this.participants[id])
        },
        // Enable for: returns true is this user is not a member of the roles provided
        enableFor(list){
            for (var idx in list){
                if(this.tryouts[this.selectedTryout][list[idx]]) return false // Found a match, so don't disable the control
            }
            return true
        },
        aToggle(id){
            // Update the "temporary" absence indicator 

            const attId = this.attendancePerSession[this.selectedSession][id]
            if(this.attendance[attId].attended===-1){
                this.updateAttendanceState({field: "attended", id: attId, value: 1}) 
                this.updateAttendance(this.attendance[attId])
            } else if(this.attendance[attId].attended===0){
                this.updateAttendanceState({field: "attended", id: attId, value: 1}) 
                this.updateAttendance(this.attendance[attId])
            } else {
                this.updateAttendanceState({field: "attended", id: attId, value: 0}) 
               // this.updateAttendance(this.attendance[attId])
                this.reasonDialog=true 
                this.reasonId=id
                this.reason=this.attendance[attId].absenceReason
            }
        },
        closeReasonDialog(){

            this.reasonDialog=false
            this.reason=''
        },
        saveReason(){
            const attId = this.attendancePerSession[this.selectedSession][this.reasonId]
            this.updateAttendanceState({field: "absenceReason", id: attId, value: this.reason}) 
            this.updateAttendance(this.attendance[attId])
            this.reason=''
            this.reasonDialog=false
        },
        saveUser: async function() {
            var validationError=false;
            if(this.firstName.length < 2 || /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(this.firstName)){
                this.$toast.error('First Name is not valid')
                validationError=true
            }
            if(this.lastName.length < 2 || /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(this.lastName)){
                this.$toast.error('Last Name is not valid')
                validationError=true
            }


            if (validationError) return 

           
                if(this.email==="") {
                    this.email=uuidv4() + "@coachsight.net"
                }
                if(this.phone === "") {
                    this.phone="Manual Entry"
                }

                // Check the user doesn't already exist...

                await this.manualUserCreate({
                    email: this.email,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    phone: this.phone,
                    eventId: this.selectedTryout
                })

                await this.createManualParticipant({
                    userId: this.userData.user.user.id,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    eventId: this.selectedTryout,
                    participantNumber: 0
                })
                //await this.findAllParticipantsForEvent(this.selectedTryout)
                await this.reset()

                this.firstName=''
                this.lastName=''
                this.participantNumber=''
                this.email=''
                this.phone=''
                this.showDialog=false

            
        },
        exportData(){
            var headings=[]
            for(var idx in this.participantHeaders){
                headings.push(`${this.participantHeaders[idx].text}`)
            }
            for(idx in this.sessionList){
                headings.push(`Attendance at ${this.sessions[this.sessionList[idx]].title}`)
                headings.push(`Attendance comment for ${this.sessions[this.sessionList[idx]].title}`)
            }
            headings.push
            var rows=[]
            rows.push(headings)
            for(idx in this.participantArray){
                var row = []
                row.push( ''+this.participantArray[idx].participantNumber)
              //  row.push(this.imageAllowed(this.participantArray[idx].id)?'Y': 'N')
                row.push(this.participantArray[idx].firstName)
                row.push(this.participantArray[idx].lastName)
                row.push(this.participantArray[idx].processed?'Y':'N')
                row.push(this.participantArray[idx].attended)
                row.push(this.participantArray[idx].absenceReason)
                row.push('details online')
                if(this.participantArray[idx].offer){
                    if(this.participantArray[idx].offer === 'Discard') {
                        row.push("No Offer")
                    } else {
                        row.push(this.teams[this.participantArray[idx].offer].name)
                    }
                } else {
                    row.push("Waitlist")
                }
                row.push(this.participantArray[idx].offerResponse?this.participantArray[idx].offerResponse:'No response')
                row.push(this.participantArray[idx].archived?'Y':'N')
                row.push(this.participantArray[idx].bypassCollection?'Y':'N')


                for(var idx2 in this.sessionList){
                    var attendanceId = this.attendancePerSession[this.sessionList[idx2]][this.participantArray[idx].id]
                    switch(this.attendance[attendanceId].attended){
                        case -1: row.push('Not Recorded');row.push('');break;
                        case 0: row.push('Did not attend');row.push(this.attendance[attendanceId].absenceReason);break;
                        case 1: row.push('Attended');row.push('');break;
                    }
                }

                rows.push(row)
            }
            let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            var filename = this.tryouts[this.selectedTryout].title+".csv"
            link.setAttribute("download", filename);
            document.body.appendChild(link); // Required for FF
    
            link.click(); // This will download the data file named "my_data.csv".  
            Vue.$toast.success(`Check your downloads folder. Your data has been exported to ${filename}`)
        },
        async reset(){
            this.preparingData=true
            await this.findAllParticipantsForEvent(this.selectedTryout)
            console.log('--------------------')
            console.log('Participants')
            console.log(this.participants)
            console.log('--------------------')
           // await this.findAllTeamsForEvent({eventId: this.selectedTryout})
            await this.findAllResponsesForEvent(this.selectedTryout)
            await this.findAllQuestionsForEvent({eventId: this.selectedTryout})
            await this.findAllSessionsForEvent(this.selectedTryout)
            await this.findAllAttendanceForEvent({eventId: this.selectedTryout})
            
            if(this.tryoutList.length===0) 
                await this.getAllTryoutsForSeason(this.selectedSeason)
/*
            if(this.tryouts[this.selectedTryout].owner || this.tryouts[this.selectedTryout].admin || this.tryouts[this.selectedTryout].support || this.tryouts[this.selectedTryout].selector) {
                this.participantHeaders.push({
                    text: 'Processed',
                    align: 'start',
                    sortable: true,
                    value: 'deskProcessed'
                })
                this.participantHeaders.push({
                    text: 'Attendance',
                    align: 'start',
                    sortable: true,
                    value: 'attended'
                })
                this.participantHeaders.push({
                    text: 'Reason',
                    align: 'start',
                    sortable: false,
                    value: 'absenceReason'

                })
                this.participantHeaders.push({
                    text: 'Application',
                    align: 'start',
                    sortable: false,
                    value: 'viewApplication'
                })
                this.participantHeaders.push({
                    text: 'Offer',
                    align: 'start',
                    value: 'teamName'
                })
                this.participantHeaders.push({
                    text: 'Response',
                    align: 'start',
                    value: 'offerResponse'
                })
                this.participantHeaders.push({
                    text: 'Withdrawn',
                    align: 'start',
                    value: 'withdrawToggle'
                }),
                this.participantHeaders.push({
                    text: 'Exclude from Data Collection',
                    align: 'start',
                    value: 'excludeToggle'
                })
            }
            */
            this.preparingData=false
        }

    },
    async created(){
        await this.reset()
        
        if(this.tryouts[this.selectedTryout].owner || this.tryouts[this.selectedTryout].admin || this.tryouts[this.selectedTryout].support) {
            this.participantHeaders.push({
                text: 'Processed',
                align: 'start',
                sortable: true,
                value: 'deskProcessed'
            })
            this.participantHeaders.push({
                text: 'Attendance',
                align: 'start',
                sortable: true,
                value: 'attended'
            })
            this.participantHeaders.push({
                text: 'Reason',
                align: 'start',
                sortable: false,
                value: 'absenceReason'

            })
            this.participantHeaders.push({
                text: 'Application',
                align: 'start',
                sortable: false,
                value: 'viewApplication'
            })
            this.participantHeaders.push({
                text: 'Offer',
                align: 'start',
                value: 'teamName'
            })
            this.participantHeaders.push({
                text: 'Response',
                align: 'start',
                value: 'offerResponse'
            })
            this.participantHeaders.push({
                text: 'Withdrawn',
                align: 'start',
                value: 'withdrawToggle'
            }),
            this.participantHeaders.push({
                text: 'Exclude from Data Collection',
                align: 'start',
                value: 'excludeToggle'
            })
       }
    },
    sockets: {
        connect(){
            // Rejoin the room if we have been disconnected
            const roomId = `attendance-${this.selectedTryout}`
            this.$socket.emit('join-room', roomId, localStorage.getItem('jwt'))
            this.$socket.emit('join-room', `data-participant-${this.selectedTryout}`, localStorage.getItem('jwt'))
        },
        'UpdatePlayerAttendance'(data){
            this.updateLocalAttendance(data)
            this.updateKey=uuidv4()
        },
        'ParticipantUpdated'(data){
            this.updateLocalParticipant(data)
            this.updateKey=uuidv4()
        }
    },
    mounted(){
        this.$root.$on('tryoutRefresh', this.refreshHandler)
        const roomId = `attendance-${this.selectedTryout}`
        this.$socket.emit('join-room', roomId, localStorage.getItem('jwt'))
        this.$socket.emit('join-room', `data-participant-${this.selectedTryout}`, localStorage.getItem('jwt'))
    },
    beforeDestroy(){
        this.$root.$off('tryoutRefresh', this.refreshHandler)
    },
    unmounted(){
        const roomId = `attendance-${this.selectedTryout}`
        this.$socket.emit('leave-room', roomId)
    },

    data() {
        return {
            refreshHandler: async() => {
                var self=this
                self.reset()
            },
            updateKey: null,
            reasonDialog: false,
            reason: '',
            reasonId:'',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            participantNumber: '',
            showDialog: false,
            preparingData: true,
            //selectedSession: 0,
            participantHeaders: [
                {
                    text: "Participant Number",
                    align: 'start',
                    sortable: true,
                    value: 'participantNumber',
                },
                /*
                {
                    text: 'Profile Image',
                    align: 'start',
                    sortable: false,
                    value: 'profileImage'
                },
                */
                {
                    text: 'First Name',
                    align: 'start',
                    value: 'firstName'
                },
                {
                    text: 'Last Name',
                    align: 'start',
                    value: 'lastName',
                },
                
            ],
            rules: {
                name: v => {
                    const pattern=/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
                    return (!pattern.test(v)) || 'Name must not contain any special characters'
                },
                length: v => {
                    return v.length >1 || 'Length must be greater than 2 characters'
                },
                number: v => {
                    const pattern=/^\+?\d+$/
                    return pattern.test(v) || 'Not a number. Must have no letters or special characters'
                },
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },

        }
    }
}

</script>

<style>
.strikeout {
    color: red;
    text-decoration-line: line-through;
}
</style>