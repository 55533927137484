<template>
        <v-row>
            <v-col class="group-col">
                <v-expansion-panels multiple>

                    <v-expansion-panel v-for="groupId in groupList" :key="groupId">
                        <v-expansion-panel-header>
                            <span class="text-h8">{{groups[groupId].name}}</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col class="role-name" v-for="roleId in roleList" :key="roleId" @drop="onDrop($event, groupId, roleId)" @dragover.prevent @dragenter.prevent>
                                    {{ roles[roleId].name  }}
                                </v-col>
                            </v-row> 
                            <v-row style="min-height:10px;">
                                <v-col v-for="roleId in roleList" :key="roleId" class="drop-zone" @drop="onDrop($event, groupId, roleId)" @dragover.prevent @dragenter.prevent>
                                    <div v-for="participantId in rolePlayerList(groupId, roleId)" :key="participantId" :draggable="allowedToDrag" @dragstart="startDrag($event, {participantId: participantId, fromGroupId: groupId, fromRoleId: roleId})">
                                        <MiniParticipantTile :participantId="participantId" class="drag-el" v-on:click.native="selectParticipant(participantId)"/>
                                    </div>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-col cols="4" style="margin:0px" v-if="showAvailable">
                <!-- Available Participant List-->
                <div @drop="onDrop($event,0,0)" @dragover.prevent @dragenter.prevent>
                <v-expansion-panels >
                    <v-expansion-panel >
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col msm="6" d="5">
                                    Available Players
                                </v-col>
                                <v-col>
                                    <ParticipantFilterDialog disableSmartFilter/>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row style="margin:0;padding:0;" >

                                <v-col style="margin:0;padding:0;" cols="4" v-for="participantId in availablePlayers" :key="participantId" :draggable="allowedToDrag" @dragstart="startDrag($event, {participantId: participantId, fromGroupId:0, fromRoleId:0})">
                                    <MiniParticipantTile :participantId="participantId" class="drag-el" v-on:click.native="selectParticipant(participantId)"/>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                </div>
            </v-col>
        </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import MiniParticipantTile from '@/components/MiniParticipantTile'
import ParticipantFilterDialog from './ParticipantFilterDialog.vue';

export default {
    props: ['activityId'],
    components: {
    MiniParticipantTile,
    ParticipantFilterDialog
},
    computed: {
        ...mapState({
            groups: state => state.groupStore.data,
            roles: state => state.roleStore.data,
            roleList: state => state.roleStore.list,
            availablePlayersByActivity: state => state.groupStore.availableByActivity,
            groupsByActivity: state => state.groupStore.groupsByActivity,
            groupRoleLists: state => state.groupStore.groupRoleLists,
            selectedTryout: state => state.navigatorStore.selectedTryout,
            participants: state => state.participantStore.data,
            participantList: state => state.participantStore.list,
            tryouts: state => state.tryoutStore.data,
            displayList: state => state.participantStore.displayList,
            nSelectedParticipant: state => state.navigatorStore.selectedParticipant,
            activityList: state => state.activityStore.list,
        }),
        groupList: {
            get() {
                return this.groupsByActivity[this.activityId]
            }
        },
        allowedToDrag(){
            if(this.tryouts[this.selectedTryout].admin || this.tryouts[this.selectedTryout].owner || this.tryouts[this.selectedTryout].sysadmin) 
                return true 
            else
                return false
        },
        showAvailable(){
            return this.allowedToDrag
        },
        availablePlayers(){
           // return this.availablePlayersByActivity[this.activityId]
           // Remove players who have already been allocated into groups

            return this.displayList.filter((participant)=>{
                var found=false
                for(var groupIdx in this.groupsByActivity[this.activityId]){
                    const groupId = this.groupList[groupIdx]
                    for(var roleId in this.groupRoleLists[groupId]){
                        if(this.groupRoleLists[groupId][roleId].includes(participant))
                            found=true
                    }
                }
                return !found
            })
        },
        selectedPlayer: {
            get(){
                return this.nSelectedParticipant
            },
            set(value){
                this.setSelectedParticipant(value)
            }
        },
    },
    methods: {
        ...mapActions('groupStore', {
            createGroup: 'create',
            findAllGroupsForEvent: 'findAllForEvent',
            addPlayerToGroup: 'addPlayer',
            movePlayerToGroup: 'movePlayer',
            removePlayerFromGroup: 'removePlayer',
            resetAvailablePlayers: 'resetAvailablePlayers',
            sortAvailablePlayers: 'sortAvailablePlayers',
            deleteGroupById: 'delete',
        }),
        ...mapActions('navigatorStore', {
            filterPlayers: 'filterPlayers',
            setSelectedParticipant: 'setSelectedParticipant'
        }),
        rolePlayerList(groupId, roleId){
            if(Object.prototype.hasOwnProperty.call(this.groupRoleLists, groupId)){
                if(Object.prototype.hasOwnProperty.call(this.groupRoleLists[groupId], roleId)){
                    return this.groupRoleLists[groupId][roleId]
                }
            }
            return []
        },
        selectParticipant(id){
            this.selectedPlayer = id
        },
        deleteGroup: async function(groupId){
            // First remove any players who have been assigned to this group
            if(Object.prototype.hasOwnProperty.call(this.groupRoleLists, groupId)){

                for(var roleId in this.groupRoleLists[groupId]){
                        for(var participantIdx in this.groupRoleLists[groupId][roleId]){
                            const participantId=this.groupRoleLists[groupId][roleId][participantIdx]
                            await this.movePlayerToGroup({
                                activityId: this.activityId,
                                fromGroupId: groupId,
                                fromRoleId: roleId,
                                toGroupId: 0,
                                toRoleId: 0,
                                participantId: participantId,
                                eventId: this.selectedTryout,
                            })
                            await this.sortAvailablePlayers({activityId: this.activityId, participants: this.participants})
                        }
                }
            }
            this.deleteGroupById(groupId) 
        },
        startDrag(evt, payload ){
            const {participantId, fromGroupId, fromRoleId} = payload
            evt.dataTransfer.dropEffect="move"
            evt.dataTransfer.effectAllowed="move"   
            evt.dataTransfer.setData('participantId', participantId)
            evt.dataTransfer.setData('fromGroupId', fromGroupId)
            evt.dataTransfer.setData('fromRoleId', fromRoleId)
           // this.filterPlayers();
        },
        async onDrop(evt, groupId, roleId){
            const participantId=evt.dataTransfer.getData('participantId')
            const fromGroupId=evt.dataTransfer.getData('fromGroupId')
            const fromRoleId=evt.dataTransfer.getData('fromRoleId')
            await this.movePlayerToGroup({
                activityId: this.activityId, 
                toGroupId: groupId,
                toRoleId: roleId,
                fromGroupId: fromGroupId,
                fromRoleId: fromRoleId,
                participantId,
                eventId: this.selectedTryout,
            })
            await this.resetAvailablePlayers({participantList: this.participantList, roleList: this.roleList, activityList: this.activityList})
            await this.sortAvailablePlayers({activityId: this.activityId, participants: this.participants})

        }
    },

    created(){
    },
    data(){
        return {
        }
    }
}

</script>

<style scoped>
.group-col{
    margin: 0px;
}

.group-box{
    margin:0px;
}

.role-name{
    margin: 0px;
    padding: 0px;
    font-size: xx-small;
}

.drag-el{
    background-color: #fff;
}
.drop-zone{
    background-color: #eee;
    margin-bottom:px;
    height: inherit;
    margin: 0px;
    padding: 0px;
    border: 1px solid black;
}
</style>