<template>
    <!-- Name only buttons for team selection-->
    <div v-if="!loading">
        <div v-if="teamSelector" class="no-boundaries">
            <div v-if="mini" :class="responsiveClass('mini-name-players-btn')"  v-on:click="onClick">
                <v-row class="no-boundary">
                    <v-col cols="12" class="mini-name-label-number" >
                        {{ participantNumber }} 
                        <div style="display:flex;flex-grow:1;"></div>
                        <v-menu offset-y v-if="!hideOffer">
                            <template v-slot:activator="{on, attrs}">
                                <v-icon style="padding-right:2px;" v-on="on" v-bind="attrs" :color="responseStatus.color" small >{{ responseStatus.icon }}</v-icon>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for="(resp, index) in responseMenu"
                                    :key="index"
                                    @click="setResponse(resp.code)"
                                    style="justify-content:space-between;width:100%"
                                    class="d-flex align-left"
                                >
                                    <v-icon width="50px" :color="resp.color">{{ resp.icon }}</v-icon>
                                    <div style="width:100%;text-align:right;">{{ resp.text }}</div>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row class="no-boundaries">
                    <v-col :class="responsiveClass('mini-name-col')" >
                        <div :class="responsiveClass('mini-name-label')" :style="backgroundColor" >
                            <p :class="responsiveClass('mini-name')+' first-name'">{{truncate(participantFirstName)}}</p>
                            <p :class="responsiveClass('mini-name')+' last-name'">{{truncate(participantLastName)}}</p>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div v-else :class="responsiveClass('name-players-btn-large-nameonly')"  v-on:click="onClick">
                <v-row class="no-boundaries">
                    <v-col cols="3" class="name-label-number" >
                        {{ participantNumber }} 
                    </v-col>
                    <v-col cols="7" :class="responsiveClass('name-col-large-nameonly')" >
                        <div :class="responsiveClass('name-label-nameonly')" :style="backgroundColor" >
                            <p :class="responsiveClass('name-nameonly')+' first-name'">{{truncate(participantFirstName)}}</p>
                            <p :class="responsiveClass('name-nameonly')+' last-name'">{{truncate(participantLastName)}}</p>
                        </div>
                    </v-col>
                    <v-col cols="2" :class="responsiveClass('response-state')">
                        <v-menu offset-y v-if="!hideOffer">
                            <template v-slot:activator="{on, attrs}">
                                <v-icon v-on="on" v-bind="attrs" :color="responseStatus.color" small >{{ responseStatus.icon }}</v-icon>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for="(resp, index) in responseMenu"
                                    :key="index"
                                    @click="setResponse(resp.code)"
                                    style="justify-content:space-between;width:100%"
                                    class="d-flex align-left"
                                >
                                    <v-icon width="50px" :color="resp.color">{{ resp.icon }}</v-icon>
                                    <div style="width:100%;text-align:right;">{{ resp.text }}</div>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
            </div>
        </div>

        <!-- normal player buttons-->
        <v-btn v-else-if="showNames" class="no-boundaries" :color="playerColor" v-on:click="onClick">
            <v-row class="no-boundaries">
                <v-col :class="responsiveClass('number-col-lg')" cols="3">
                    <div :class="responsiveClass('name-number')" :style="numberColor">
                        {{participantNumber}}
                    </div>
                </v-col>
                <v-col :class="responsiveClass('name-col-large')" cols="9">
                    <div v-if="sortByFirstName===1" :class="responsiveClass('name-label')">
                        <p class="name first-name">{{truncate(participantFirstName)}}</p>
                        <p class="name last-name">{{truncate(participantLastName)}}</p>
                    </div>
                    <div v-else-if="sortByFirstName===2" :class="responsiveClass('name-label')">
                        <p class="name first-name">{{truncate(participantLastName)}}</p>
                        <p class="name last-name">{{truncate(participantFirstName)}}</p>
                    </div>
                    <div v-else :class="responsiveClass('name-label')">
                        <p class="name first-name">{{truncate(participantFirstName)}}</p>
                        <p class="name last-name">{{truncate(participantLastName)}}</p>
                    </div>
                </v-col>
            </v-row>
        </v-btn>
        <v-btn v-else :class="responsiveClass('sm-num-players')" :color="playerColor" v-on:click="onClick">
            <div class="no-boundaries">
                <p class="sm-num">{{participantNumber}}</p>
            </div>
        </v-btn>

            <!-- REMOVED COMMENTS INDICATOR, BUT LEFT IT HERE JUST IN CASE
            <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                    <v-icon v-bind="attrs" v-on="on" x-small class="comments" :style="indicatorStyle" v-if="commentsPresent">mdi-circle</v-icon>
                </template>
                {{ indicatorTip }}
            </v-tooltip>
        -->




    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Color from 'colorjs.io/dist/color.legacy.cjs'


export default{
    props: {
        showNames: {
            type: Boolean,
            default: true
        },
        sortByFirstName: {
            type: Boolean,
            default: true,
        },
        participantId: {
            type: String,
            default: "0"
        },
        teamSelector: {
            type: Boolean,
            default: false,
        },
        mini: {
            type: Boolean,
            default: false,
        },
        frameColor: {
            type: String,
            default: 'black'
        },
        selected: {
            type: Boolean,
            default: false
        },
        hideOffer: {
            type: Boolean,
            default: false,
        }
    },
    components: {},
    computed: {
        ...mapState({
            participants:       state=>state.participantStore.data,
            participantStatus:  state => state.participantStore.status,
            playerskills:       state => state.playerskillStore.data,
            playerskillsStatus: state => state.playerskillStore.status,
            comments:           state=>state.commentStore.data,

            tryouts:            state=>state.tryoutStore.data,
            selectedTryout:     state=>state.navigatorStore.selectedTryout,

        }),
        backgroundColor(){
            if(this.selected) return "background:lightgreen"
            return ""
        },
        loading(){
            return this.participantStatus.loading || this.playerskillsStatus.loading;
        },
        commentStrength(){
            return this.participants[this.participantId].comments/this.tryouts[this.selectedTryout].selectorCount*100
        },
        participantNumber(){
            if(!this.participantId || this.participantId===0 || !this.participants[this.participantId]) return '-'
            return this.participants[this.participantId].participantNumber 
        },
        commentsPresent(){
            if(!this.participantId || this.participantId===0) return false
            return this.participants[this.participantId].comments>0
        },
        /*
        indicatorStyle(){
            if(!this.participantId || this.participantId===0) return false
            var style='display:none;'
            if(this.commentStrength>=50) style="color:limegreen;"
            else if(this.commentStrength>=25) style="color:yellow;"    

            if(this.showNames) style=style+"position: absolute; transform: translate(-59px, -39px);"
            else style=style+"position: absolute; transform: translate(-12px, 0px);"

            return style
        },
        indicatorTip(){
            return this.participants[this.participantId].comments + ' comments recorded'
        },
        */
        playerColor: function(){
            let complete=0

            if(this.participantId && this.participantId !== 0 && this.playerskills[this.participantId]){
                complete = this.playerskills[this.participantId].complete
            }                

            if(complete < 25) return `#e80909`
            if(complete < 50) return `#e88009`
            if(complete < 75) return `#e8e109`
            return `#09e854`

        },
        complete(){
            let complete=0

            if(this.participantId && this.participantId !== 0){
                complete = this.playerskills[this.participantId].complete
            }                
            return complete

        },
        numberColor(){
            if(this.complete < 25) return `color:white`
            if(this.complete < 50) return `color:black`    
            if(this.complete < 75) return `color:black`
            return `color:black`
        },
        participantFirstName(){
            if(!this.participantId || this.participantId===0) return '-'
            return this.participants[this.participantId].firstName
        },
        participantLastName(){
            if(!this.participantId || this.participantId===0) return '-'
            return this.participants[this.participantId].lastName
        },
        responseStatus(){
            if(!this.participantId || this.participantId===0) return {icon:'', color:'white'}
            if(this.participants[this.participantId].offer===null) return {icon:'', color:'white'}
            if(this.participants[this.participantId].offerResponse===null) return {icon:'mdi-help-box', color:'orange'}
            if(this.participants[this.participantId].offerResponse==='') return {icon:'', color:'white'}
            if(this.participants[this.participantId].offerResponse==='decline') return {icon:'mdi-thumb-down', color: 'red'}
            if(this.participants[this.participantId].offerResponse==='accept') return {icon:'mdi-thumb-up', color: 'green'}
            if(this.participants[this.participantId].offerResponse==='sent') return {icon:'mdi-email-fast-outline', color: 'blue'}
            return {icon:'', color:'white'}
        },
        showOptionIcon(){
            return (this.tryouts[this.selectedTryout].owner || this.tryouts[this.selectedTryout].admin || this.tryouts[this.selectedTryout].headcoach)
        }
    },
    methods: {
        ...mapActions('participantStore', {
            updateParticipantProp: 'propertyUpdate',
            updateParticipant: 'update',
            acceptOffer: 'acceptOffer',
            declineOffer: 'declineOffer',
            clearOffer: 'clearOffer',
        }),
        responsiveClass(className){
            if(this.$vuetify.breakpoint.smAndDown){
                return className+' mobile'
            }
            return className
        },
        async setResponse(code){
            this.updateParticipantProp({field: "offerResponse", id: this.participantId, value: code})
            this.updateParticipant(this.participants[this.participantId])

            if(code === 'accept'){
                await this.acceptOffer(this.participantId)
            } else if (code==='decline') {
                await this.declineOffer(this.participantId)
            } else {
                await this.clearOffer(this.participantId)
            }
        },
        onClick(){
            this.$emit('click',this.participantId)
        },
        truncate(string){
            if(!string) return ''
            if(string.length>13) return string.substring(0,8)
            return string
        },
        invertColor(hex) {
            return (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()

        },
        contrastColor(hex) {
            // Y = 0.2126 R + 0.7152 G + 0.0722 B
            // S = (max(R, G, B) - min(R, G, B)) / max(R, G, B)

            // The second one is the calculation from the HSV colour space.

            // If the saturation is low enough (pick any value you like; something 
            // between 0.3 and 0.5 would work fine), check the luminance; 
            // if that's > 0.5, your contrasting colour is black, else if 
            // it's < 0.5 the colour is white. For exactly Y = 0.5, both work.


            if (hex.indexOf('#') === 0) {
                hex = hex.slice(1);
            }
            if (hex.length === 3) {
                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
            }
            const red = Number('0x'+hex[0]+hex[1])/255
            const green = Number('0x'+hex[2]+hex[3])/255
            const blue = Number('0x'+hex[4]+hex[5])/255

            const gamma = 2.2
            const L = 0.2126 * Math.pow( red, gamma ) + 0.7152 * Math.pow( green, gamma ) + 0.0722 * Math.pow( blue, gamma );
            

            const use_black = ( L > Math.pow( 0.5, gamma ) );

            return use_black?'#000000FF':'#FFFFFFFF'


        },
    },
    created(){
    },
    data(){
        return {
            Color: Color,
            iconColor: [
                "gray",
                "red",
                "orange",
                "yellow",
                "lime",
                "light-green accent-3"
            ],
            responseMenu: [
                {
                    text: "Unspecified",
                    icon: "mdi-help-box",
                    color: "orange",
                    code: null
                },
                {
                    text: "Offer Made",
                    icon: "mdi-email-fast-outline",
                    color: "blue",
                    code: "sent"

                },
                {
                    text: "Offer Declined",
                    icon: "mdi-thumb-down",
                    color: "red",
                    code: 'decline'
                },
                {
                    text: "Offer Accepted",
                    icon: "mdi-thumb-up",
                    color: "green",
                    code: "accept"
                }
            ]
        }
    }
}

</script>

<style scoped>

.mini-name-players-btn{
    font-size: 1rem;
    width: 90%;
    margin: 5%;
    border-style:solid;
    border-radius: 4px;;
    padding: 0px 0px 0px 0px;
    border-width:1px;
    border-color:grey;
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.34));

}
.mini-name-label-number{
    margin-top:auto;
    margin-bottom:auto;
    background:lightGrey;
    height:20px;
    font-size:medium;
    font-weight:600;
    padding:0px 0px 0px 5px;

    border-top-left-radius:4px;
    border-top-right-radius:4px;
    display:flex;
    flex-direction:row;

}
.mini-name-col {
    padding:0px 0px 0px 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.mini-name-label{
    margin-top:auto;
    margin-bottom:auto;
    background:white;
    height:28px;
    padding-right:0px;
    border-bottom-left-radius:4px;
    border-bottom-right-radius:4px;

}
.mini-name{
    margin-top:0px;
    padding-top:0;
    padding-bottom: 0px;
    text-align:left;
    font-size:xx-small;
    padding-left:5px;
}

.mini-response-state{
    margin-top:auto;
    margin-bottom:auto;
    background:white;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height:28px;
    padding:0px;
    text-align:right;
    padding-right:5px;

}





.name-players-btn-large-nameonly{
    font-size: 1rem;
    width: 90%;
    margin: 5px;
    border-style:solid;
    border-radius: 4px;;
    padding: 0px 0px 0px 0px;
    border-width:1px;
    border-color:grey;
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.34));
    min-width:100px;
}

.name-col-large-nameonly{
    padding:0px 0px 0px 0px;
}

.name-nameonly{
    margin-top:0px;
    padding-top:0;
    padding-bottom: 0px;
    text-align:left;
    font-size:xx-small;
    padding-left:5px;
}

.name-label-nameonly{
    margin-top:auto;
    margin-bottom:auto;
    background:white;
    height:28px;
    padding-right:0px;
    line-height:0.8rem;
    /*
    border-top-left-radius:4px;
    border-bottom-left-radius:4px;
    */

}

.name-label-number{
    margin-top:auto;
    margin-bottom:auto;
    background:lightGrey;
    height:28px;
    padding:0px;
    border-top-left-radius:4px;
    border-bottom-left-radius:4px;
    margin:0px;

}
.response-state{
    margin-top:auto;
    margin-bottom:auto;
    background:white;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height:28px;
    padding:0px;
    text-align:right;
    padding-right:5px;

}
.number-col-lg{
    padding:0px;
    margin: 0px;
    text-align:center;
    margin-top:auto;
    margin-bottom:auto;
    display:flex;
    vertical-align: middle;
    color:white;
    width:50px;

}
.number-col-lg.mobile{
}

.name-number {
    font-size:x-large;
    font-weight:600;
    font-family:'Varela Round';
    color:white;
    flex-grow:2;
}
.name-col-large{
    padding:0px 0px 0px 5px;
}
.name-label{
    margin-top:auto;
    margin-bottom:auto;
    background:white;
    height:28px;
    padding-right:5px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;;
    margin-right:-10px;
    padding-left:5px;
    width:100px;
    overflow:hidden;

}

.name{
    margin-top:0px;
    padding-top:0;
    padding-bottom: 0px;
    text-align:left;
    font-size:xx-small;
}
.first-name{
    margin-top:auto;
    font-weight:600;
    margin-bottom:0px;
    text-wrap:nowrap
}

.last-name{
    margin-bottom:auto;
    text-wrap:nowrap
}
.no-boundaries{
    margin:0px;
    padding:0px;
}

.sm-num{
    margin-top:auto;
    margin-bottom:auto;
    font-size:x-large;
    font-weight:600;
    font-family:'Varela Round';
    color:white;
}

.v-btn:not(.v-btn--round).v-size--default {
    padding:0px;
}
.v-btn, .v-btn__content {
    max-width:100%;
    margin:2px;
}
/*
-------------------------
*/


</style>