import { appquestionService } from "../_services/appquestion.service";
//import {router} from '../helpers'
import Vue from "vue";

function initialiseState(){
    return {
        status: {},
        data: {},
        meta: {},
        internal: {},
        list: [],
        listByContext: initialListState(),
        error: null
    }
}

function initialListState(){
    return {
            devform: [],
            devregistration: [],
            trialregistration: [],
            episodeform: [],
            participantreflection: [],
    }
}
const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async loadAllQuestionsForEvent({dispatch, commit}, payload){
        try {
            commit('findAllForEventRequest')
            const appQuestions = await appquestionService.findAllForEvent(payload.eventId)
            commit('findAllForEventSuccess', appQuestions)
        } catch(error) {
            commit('findAllForEventFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },

    /* Make this one redundant
    async findAllForEvent({dispatch, commit}, id) {
        try {
            commit('findAllForEventRequest')
            const appQuestions = await appquestionService.findAllForEvent(id)
            commit('findAllForEventSuccess', appQuestions)
        } catch(error) {
            commit('findAllForEventFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    */
    async addQuestion({dispatch, commit}, question) {
        try{
            commit('addQuestionRequest')
            const response = await appquestionService.addQuestion(question) 
            setTimeout(() => {
                commit('addQuestionSuccess', response)
                dispatch('alert/success', 'Your new question has been created', {root: true})
            })
        } catch(error) {
            setTimeout(() => {
                commit('addQuestionFailure', error)
                dispatch('alert/error', error, {root: true})
            })
        }
    },
    async updateQuestion({dispatch, commit}, question) {
        try{
            commit('updateQuestionRequest')
            await appquestionService.updateQuestion(question)
            setTimeout(() => {
                commit('updateQuestionSuccess')
            })
        } catch(error) {
            setTimeout(() => {
                commit('updateQuestionFailure', error)
                dispatch('alert/error', error, {root: true})
            })
        }
    },

    update({commit},payload) {
        commit('update', payload)
    },
    updateListOrder({commit}, list){
        commit('updateListOrder', list)

    },
    async delete({dispatch, commit}, questionId) {
        try{
            commit('deleteQuestionRequest', questionId)
            await appquestionService.deleteQuestion(questionId)
            commit('deleteQuestionSuccess')
        } catch(error) {
            commit('updateQuestionFailure', error)
            dispatch('alert/error','One or more questions failed to be deleted')
        }
    }
}
function buildQuestion(element) {

    const id = element.id

    state.list.push(id)
    if(!Object.prototype.hasOwnProperty.call(state.listByContext, element.context)){
        state.listByContext[element.context]=[]
    }
    state.listByContext[element.context].push(id)
    Vue.set(state.data, id, element)
    Vue.set(state.meta, id, {
        delete: false,
    })
}

function sortListByOrder() {
    state.list.sort((a,b) =>  {
        return state.data[a].order - state.data[b].order
    })
    for(var i in state.listByContext){
        state.listByContext[i].sort((a,b) => {
            return state.data[a].order - state.data[b].order
        })
    }
}


const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    findAllForEventRequest(state) {
        state.status={loading: true}
        state.list=[]
        state.listByContext=initialListState()
        state.error=null
    },
    findAllForEventSuccess(state, appQuestions){
        state.list=[]
        state.data={}
//        state.questions={}

        for (var idx in appQuestions) {
            buildQuestion(appQuestions[idx])
        }
        sortListByOrder()
        state.status={}
    },
    findAllForEventFailure(state, error){
        state.status={}
        state.error = error
    },

    // ------------- CREATE ------------- \
    
    addQuestionRequest(state) {
//        state.status={loading: true}
        state.error=null
    },
    addQuestionSuccess(state, appQuestion) {
//        state.status={}
       
        buildQuestion(appQuestion.appquestion)
    //    sortListOnOrder()
    },
    addQuestionFailure(state, error) {
        state.status={}
        state.error = error
    },

    // ------------- UPDATE ------------- \
    
    updateQuestionRequest(state) {
//        state.status={loading: true}
        state.error=null
    },
    updateQuestionSuccess(state) {
        state.internal={}
    },
    updateQuestionFailure(state, error) {
        state.status={}
        state.error = error
    },

    deleteQuestionRequest(state, questionId){
        state.internal={deleting: true, id: questionId}
        state.error=null
    },
    deleteQuestionSuccess(state) {
        const order = state.data[state.internal.id].order
        delete state.data[state.internal.id]       
        delete state.meta[state.internal.id]

        // Update the "order" attributes to close the gap
        const keys = Object.keys(state.data)
        for(var idx in keys){
            if(state.data[keys[idx]].order >= order){
                state.data[keys[idx]].order--
            }
        } 
        
        const index = state.list.indexOf(state.internal.id)
        state.list.splice(index,1)

        // Sort the lists again
        sortListByOrder()

        state.internal={}
    },
    deleteQuestionFailure(error) {
        state.status={}
        state.error = error
    },


    // ----------- DATA UPDATE ------- \\
    update(state, payload) {
        const metaFields="delete"

        const {field, id, value} = payload
        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    },

    //-------- UPDATE LIST ORDER -------\\

    updateListOrder(state, list) {
        state.list = list
        var count=0;
        for(var idx in state.list) {
            const id = state.list[idx]
            state.data[id].order = count++
        }
        sortListByOrder()
    }
}

export const appquestionStore = {
    namespaced: true,
    state,
    actions,
    mutations
}
