<template>
    <div style="width:100%" @click="myClickFn">
        <div v-if="miniScreen || forceLandscape">
            <v-row v-if="participantId"  class="playerbox-mobile" >
                <v-col cols="8" style="margin-top:0px;display:flex;flex-direction:column" >
                    <v-row class="selected-label-mobile" style="margin-top:0px;margin-bottom:0px;"> 
                        Selected Player: 
                    </v-row>
                    <v-spacer/>
                    <v-row style="margin-top:auto;margin-bottom:auto;">
                        <v-col cols="3" class="no-boundaries" style="margin:auto;">
                            <div class = "mobile-name-col">
                                <div class="selected-number"> 
                                    {{  participantNumber }} 
                                </div> 
                            </div>
                        </v-col>
                        <v-col cols="7" class="no-boundaries">
                            <div class="playerfirstname-mobile">
                                {{ firstName }}
                            </div>
                            <div class="playerlastname-mobile">
                                {{  lastName }}
                            </div>
                        </v-col>
                        <v-col cols="2" class="role-wrapper">
                            <div v-if="role1" class="role-circle" style="margin-bottom:2px;">{{ role1 }}</div>
                            <div v-if="role2" class="role-circle">{{ role2 }}</div>
                        </v-col>
                    </v-row>
                    <v-row> 
                        <v-col cols="10" style="margin:0px;padding:0px;">
                        </v-col>
                        <v-col cols="10" style="margin:0px;padding:0px;">
                        </v-col>
                    </v-row>
                </v-col>

                <v-col v-if="participantId" cols="4" class="mobile-image text-right">
                    <participant-profile-image :participantId="participantId" :size="size"/>
                </v-col>
            </v-row>
            <v-row v-else class="playerbox-mobile">
                <div style="font-family: 'Varela Round';font-weight:600;font-size:medium;color:white;margin-left:auto;margin-right:auto">
                    No Trialist Selected
                </div>

            </v-row>
        </div>
        <div v-else class="playerbox">
            <div v-if="!participantId" style="font-family: 'Varela Round';font-weight:600;font-size:medium;color:white;margin-left:auto;margin-right:auto">
                No Trialist Selected
            </div>
            <div v-else>
                <v-row>
                    <v-col class="selected-label" cols="7">
                        Selected<br/>Player
                    </v-col>
                    <v-col>
                        <span class="selected-number">{{ participantNumber }}</span>
                    </v-col>
                </v-row>
                <v-row class="image-row">
                    <participant-profile-image :participantId="participantId" :size="size"/>
                </v-row>
                <v-row class="name-row">
                    <v-col cols="9">
                        <div>
                            <div class="playerfirstname">
                                {{ firstName  }}
                            </div>
                            <div class="playerlastname">
                                {{  lastName }}
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="1" class="role-wrapper">
                        <div v-if="role1" class="role-circle" style="margin-bottom:2px">{{ role1 }}</div>
                        <div v-if="role2" class="role-circle">{{ role2 }}</div>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ParticipantProfileImage from '@/components/NewParticipantProfileImage'

export default {
    props: {
        participantId : {
            default: '',
        },
        size: {
            default:null,
        }, 
        clickFn: {
            default: ()=>{}
        },
        forceLandscape: {
            type: Boolean,
            default: false
        },
    },
    components: {
        ParticipantProfileImage,
    },
    computed: {
        ...mapState({
            participant: state => state.participantStore.participant,
            participants: state => state.participantStore.data,
            participantStatus: state => state.participantStore.status,
            roles:      state => state.roleStore.data,
        }),
        participantNumber(){
            if(!this.participantId || !this.participants[this.participantId] || this.participantId ===0 || this.participantId=== 'null') return '-'
            return this.participants[this.participantId].participantNumber
        },
        firstName(){
            if(!this.participantId || !this.participants[this.participantId] || this.participantId ===0 || this.participantId === 'null') return '-'
            return this.participants[this.participantId].firstName
        },
        lastName(){
            if(!this.participantId || !this.participants[this.participantId] || this.participantId ===0 || this.participantId === 'null') return '-'
            return this.participants[this.participantId].lastName
        },
        role1(){
            if(!this.participantId || !this.participants[this.participantId] || this.participantId ===0 || this.participantId === 'null') return null
            if(!this.participants[this.participantId].prefRole1) return null
            return this.roles[this.participants[this.participantId].prefRole1].shortCode
        },
        role2(){
            if(!this.participantId || !this.participants[this.participantId] || this.participantId ===0 || this.participantId === 'null') return null
            if(!this.participants[this.participantId].prefRole2) return null
            return this.roles[this.participants[this.participantId].prefRole2].shortCode
        },
        
        cssProps(){
            return {
                '--size': this.size
            }
        },
        miniScreen(){
            if (this.$vuetify.breakpoint.smAndDown) {
                return true;
            }
            return false;
        }
    },
    methods: {},
    created(){
        if(this.clickFn && this.clickFn!==''){
            this.myClickFn=this.clickFn
        }
    },
    data(){
        return {
            myClickFn: ()=>{}
        }
    }
}
</script>

<style scoped>
.role-circle{
    color: white;
    border-radius: 10px;
    width: 30px;

    background: #fff;
    border: 2px solid #666;
    color: #666;
    text-align: center;

    font-size:  0.7em;
    font-weight:600;
    font-family: 'Varela Round';
    margin-left:-15px;
}
.role-wrapper{
    margin-top:auto;
    margin-bottom:auto;
    padding:0px;
}
.role-wrapper-mobile{
    margin-top:auto;
    margin-bottom:auto;
    display: flex;
    flex-flow:row;
}
.name-col {
    margin-top: auto;
    margin-bottom: auto;
}
.name-row {
    margin: 0px;
    background: #028385;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.mobile-name-col {
    margin-bottom: 0;
    background: #028385;
    border-radius: 10px;
    padding:0px;

}
.image-row {
    margin: 0px;
    padding-bottom:5px;
}
.playerbox {
    background: blue;
    border-radius: 10px;
    width: var(--size);
}

.playerbox-mobile {
    background: blue;
    border-radius: 10px;
    margin-top:10px;
    margin-right:auto;
    margin-left: auto;
    width:100%;
}
.selected-label-mobile {
    font-size:10px;
    color: white;
    font-weight: 600;
    margin-top:0px;
    margin-bottom:auto;
}
.selected-label {
    font-size:10px;
    color: white;
    font-weight: 600;
    margin-top:0px;
    margin-bottom:5px;
}

.mobile-image{
    padding:0px;
    margin-top:auto;
    margin-bottom:auto;
    padding-bottom:5px;
}
.selected-number {
    color: white;
    font-weight: 600;
    font-size: 20px;
    padding:0px;
    margin-bottom:auto;
}
.playerfirstname {
    font-size: 15px;
    font-weight: 900;
    color: white;
    background: #028385;
    text-align:center;
}
.playerfirstname-mobile {
    font-size: 15px;
    font-weight: 900;
    color: white;
    margin-top:0px;
    border-top-right-radius: 10px;
    margin:0px;
}
.playerlastname {
    font-size: 15px;
    color: white;
    background: #028385;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
}
.playerlastname-mobile {
    font-size: 15px;
    color: white;
}
</style>