<template>

    <v-expansion-panel v-if="!loading">
        <v-expansion-panel-header disable-icon-rotate style="padding:5px;">
            <template v-slot:actions>
                <v-dialog v-model="confirmDeleteDialog" max-width="60%">
                    <template v-slot:activator="{on, attrs}">
                        <v-icon small color="red" v-on="on" v-bind="attrs">mdi-delete</v-icon>
                    </template>
                    <v-card>
                        <v-card-title>Confirm Criterion Deletion</v-card-title>
                        <v-card-text>
                            You cannot undo this operation. All information for this criterion will be deleted permanently, including scores that have eben provided and feedback attached to scores. Are you sure you want to continue?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn text @click="confirmDeleteDialog=false">Close</v-btn>
                            <v-btn color="red darken-1" text @click="confirmedDelete">Delete</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-icon>mdi-chevron-down </v-icon>
            </template>
            <v-row style="margin:0px;padding:0px;">
                <v-col cols="1" style="margin:0px;padding:0px;text-align:left">
                    <v-icon class="handle">mdi-drag-horizontal-variant</v-icon>
                </v-col>
                <v-col style="text-align:left;margin:auto 0px auto 0px;padding:0px 0px 0px 10px" :class="{strike: meta[criterionId].archive}">
                    {{criteria[criterionId].description}}
                </v-col>
                <v-spacer/>
            </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-row>
                <v-col cols="4">
                    <v-text-field
                        v-model="description"
                        prepend-icon="mdi-information"
                        label="Criterion Name"
                    /> 
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="5" style="margin-top:0px;margin-bottom:auto;">
                    <v-radio-group style="margin:0px;padding:0px;" v-model="selectedScore" v-for="item in scores" :key="item.score">
                        <v-radio :value="item.score">
                            <template v-slot:label>
                                <v-text-field dense hide-details v-model="item.value"  v-on:keyup="item.changeHook()">
                                    <template v-slot:prepend>
                                        <v-icon :color="item.color" large>{{ item.icon }}</v-icon> 
                                    </template>
                                </v-text-field>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols=7 style="margin-top:0px; margin-bottom:auto;">
                    <v-card >
                        <v-card-title>
                            Feedback associated with a score of <v-icon style="margin-left:5px;" :color="scores[5-selectedScore].color" large>{{ scores[5-selectedScore].icon }}</v-icon> 
                        </v-card-title>
                        <v-card-text>
                            <v-btn small dense text color="primary" class="cs-btn small" @click="addFeedback(selectedScore)">Add Feedback</v-btn>
                            <v-list :key="updateKey" v-if="feedbackByCriterionAndScore[criterionId]">
                                <FeedbackLine  :feedbackId="item" :parentComponentId="componentId" v-for="item in feedbackByCriterionAndScore[criterionId][selectedScore]" :key="item"/>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {v4 as uuidv4} from 'uuid'


import FeedbackLine from '@/components/feedbackLine'

export default {
    props: ['criterionId'],
    components: {
        FeedbackLine,
    },
    computed: {
        ...mapState({
            store: state => state.criterionStore,
            criteria: state => state.criterionStore.data,
            list: state => state.criterionStore.list,
            meta: state => state.criterionStore.meta,
            status: state => state.criterionStore.status,
            error: state => state.criterionStore.error, 
            selectedTryout:     state=> state.navigatorStore.selectedTryout,

            feedbackByCriterionAndScore:        state => state.feedbackStore.listByCriterionAndScore,
            feedback :                  state => state.feedbackStore.data,
            feedbackStatus:     state => state.feedbackStore.status,
        }),
        loading(){
            return this.feedbackStatus.loading
        },
        description: {
            get() {
                return this.criteria[this.criterionId].description
            },
            set(value) {
                this.flagChanges()
                return this.update({field: 'description', id: this.criterionId, value})
            }
        },
        order: {
            get() {
                return this.criteria[this.criterionId].order
            },
            set(value) {
                this.flagChanges()
                return this.update({field:'order', id: this.criterionId, value})
            }
        },

        flagToDelete: {
            get() {
                return this.meta[this.criterionId].archive
            },
            set(value) {
                const result =  this.propertyUpdate({field: 'archive', id: this.criterionId, value})
                return result
            }
        },
    },
    methods: {
        ...mapActions('criterionStore', {
            update: 'update',
            propertyUpdate: 'update',
            updateCriterion: 'updateCriterion',
            archiveCriterion: 'archiveCriterion',
        }),
        ...mapActions('feedbackStore', [
            'createFeedback',
        ]),
        flagForDeletion: function() {
            this.flagToDelete=true
            this.flagChanges()
        },
        unflagForDeletion: function() {
            this.flagToDelete=false
            this.flagChanges()
        },
        flagChanges(){
            this.changes=true
 //           this.$root.$emit("settings-criteria-changes-made", this.componentId)
            this.$root.$emit("settings-changes-made", this.componentId)
        },
        async saveDetails(){
            this.changes=false
            this.updateCriterion(this.criteria[this.criterionId])

            // Update all the feedback too...


            this.$root.$emit("settings-criteria-changes-cleared", this.componentId)
            this.$root.$emit("settings-changes-cleared", this.componentId)
            this.$store.dispatch('alert/success', 'Tryout Updated', {root:true})
        },
        confirmedDelete(){
            this.archiveCriterion(this.criterionId)
            this.confirmDeleteDialog=false
            this.$store.dispatch('alert/success', 'Skill criterion deleted', {root:true})

        },
        updateText(level){
            this.$nextTick(()=>{
                this.update({field: `level${level}`, id: this.criterionId, value: this.scores[5-level].value})
                this.flagChanges()

            })
        },
        async addFeedback(associatedScore){
            try{
                await this.createFeedback({
                    eventId: this.selectedTryout,
                    criterionId: this.criterionId,
                    associatedScore: associatedScore,
                    text: '--- New feedback ---'
                })
                this.updateKey=uuidv4()
            } catch (error){
                console.error('Error creating new feedback')
                console.error(error)
            }

        }
    },
    async created(){
        this.componentId = uuidv4()
    },
    mounted(){
        this.$root.$on('submit-all-changes', () => {
            if(this.changes){
                this.saveDetails()
            }
        })
        this.$root.$on('submit-all-changes', () => {
            var self=this
            if(this.changes){
                this.saveDetails()
                self.changes=false
            }
        })
        this.$root.$on(`${this.componentId}-listupdate`, () => {
            this.updateKey=uuidv4()
        })

        this.scores[0].value= this.criteria[this.criterionId].level5
        this.scores[1].value= this.criteria[this.criterionId].level4
        this.scores[2].value= this.criteria[this.criterionId].level3
        this.scores[3].value= this.criteria[this.criterionId].level2
        this.scores[4].value= this.criteria[this.criterionId].level1
        this.scores[5].value= this.criteria[this.criterionId].level0

    },
    data(){
        return {
            updateKey:'00',
            selectedScore:5,
            confirmDeleteDialog:false,
            componentId: 0,
            changes: false,
            scores: [
                {
                    score: 5,
                    icon: 'mdi-numeric-5-circle',
                    color: 'green',
                    value: '',
                    changeHook: ()=>{ this.updateText(5) }
                },
                {
                    score: 4,
                    icon: 'mdi-numeric-4-circle',
                    color: 'lime',
                    value: '',
                    changeHook: ()=>{ this.updateText(4) }
                },
                {
                    score: 3,
                    icon: 'mdi-numeric-3-circle',
                    color: 'yellow',
                    value:'',
                    changeHook: ()=>{ this.updateText(3) }
                },
                {
                    score: 2,
                    icon: 'mdi-numeric-2-circle',
                    color: 'orange',
                    value: '',
                    changeHook: ()=>{ this.updateText(2) }
                },
                {
                    score: 1,
                    icon: 'mdi-numeric-1-circle',
                    color: 'red',
                    value: '',
                    changeHook: ()=>{ this.updateText(1) }
                },
                {
                    score: 0,
                    icon: 'mdi-numeric-0-circle',
                    color: 'gray',
                    value: '',
                    changeHook: ()=>{ this.updateText(0) }
                },

            ]
        }
    }
}
</script>

<style>
.cs-btn{
    font-family: "Varela Round";
}
.cs-btn.small{
    font-size:10px;
}
</style>